import React from "react";
import { NoSellSheets, SellSheetListEntry } from "./SellsheetList";
import { SellsheetFromRoute } from "./SellsheetPreview";
import { useGameIdFromRoute } from "../games/WithGameIdFromRoute";
import { Path } from "../Path";
import { useGameSellsheets } from "@seabrookstudios/pitch2table";
import { idExtractor, useMakeListAndDetail, useMakeListBuilder } from "../core/useMakeLayout";
import AddSellsheetFullScreenDialog from "./AddSellsheetFullScreenDialog";

export const SellsheetListAndDetail = () => {
  const gameId = useGameIdFromRoute();
  const Action = () => <AddSellsheetFullScreenDialog gameId={gameId} />;
  const useWrappedQuery = () => useGameSellsheets(gameId);

  const useMakeList = useMakeListBuilder(
    SellSheetListEntry,
    idExtractor,
    "Sell Sheets",
    NoSellSheets,
    Action,
    undefined,
    Path.game(gameId).link()
  );

  const ListAndDetail = useMakeListAndDetail(useWrappedQuery, [], "game-sell-sheet", useMakeList, SellsheetFromRoute);

  return <ListAndDetail />;
};
