import React from "react";
import { useGameIdFromRoute, useTopicIdFromRoute } from "../games/WithGameIdFromRoute";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { Path } from "../Path";
import { useNavigate } from "react-router-dom";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import { UserAvatar } from "../users/UserAvatar";
import Typography from "@mui/material/Typography";
import { UserName } from "../users/UserName";
import { formatTopicDate } from "./model";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import { carissma } from "../theme";
import { TopicTypeLabels } from "@seabrookstudios/pitch2table-core";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").TopicForApp} props.item
 */
export const TopicListEntry = ({ item }) => {
  const gameId = useGameIdFromRoute();
  const topicId = useTopicIdFromRoute();

  const navigate = useNavigate();

  const selected = topicId === item.id;

  const onClickHandler = () => {
    navigate(Path.game(gameId).topic(item.id));
  };

  return (
    <ListItemButton id={`topic-${item.id}`} alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemAvatar>
        <UserAvatar userId={item.lastModifiedByUserId} />
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box display="flex" alignItems="center">
            <Typography variant="inherit" fontWeight="bold">
              {item.name} <Chip label={TopicTypeLabels[item.type]} size="small" />
            </Typography>
          </Box>
        }
        secondary={
          <Typography variant="inherit">
            <UserName userId={item.lastModifiedByUserId} />
            {` · `}
            <Typography variant="inherit" as="span">
              {formatTopicDate(item.created)}
            </Typography>
          </Typography>
        }
      />
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: carissma }}>{item.messages}</Avatar>
      </ListItemAvatar>
    </ListItemButton>
  );
};
