import React from "react";
import { DateTime } from "luxon";
import Alert from "@mui/material/Alert";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { NoItemsFound } from "../publisher/NoItemsFound";
import { useNavigate, useParams } from "react-router-dom";
import { Path } from "../Path";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Image from "@mui/icons-material/Image";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import Box from "@mui/material/Box";
import { formatBytes } from "./formatBytes";

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/pitch2table-core").Rules} props.item
 * @param {number} props.filteredIndex
 * @param {number} props.filteredCount
 */
export const RulesListEntry2 = ({ item, filteredIndex, filteredCount }) => {
  const params = useParams();
  const navigate = useNavigate();

  const selected = params.attachmentId === item.id;

  const onClickHandler = () => {
    navigate(Path.game(item.gameId).rule(item.id));
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemText
        primary={
          <Stack direction="row">
            <Box pl={0} pr={1}>
              {item.mimeType === "application/pdf" ? <PictureAsPdf /> : <Image />}
            </Box>
            <Typography variant="inherit">
              v{filteredCount - filteredIndex} ({formatBytes(item.bytes, 1)})
            </Typography>
          </Stack>
        }
        secondary={`Uploaded ${DateTime.fromMillis(item.uploadedTimestamp).toFormat("h:mm a, DDD")}`}
      />
    </ListItemButton>
  );
};

export const NoRules = () => (
  <NoItemsFound>
    <Alert severity="info">
      Here you can upload your game's rules as discrete versions. As publishers review games at different speeds, your
      game and the rules may have evolved since you pitched it. In the publisher interest section, you can record what
      version of the rules you sent to each publisher.
    </Alert>
  </NoItemsFound>
);
