import React from "react";
import { DateTime } from "luxon";
import Alert from "@mui/material/Alert";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { NoItemsFound } from "../publisher/NoItemsFound";
import { useNavigate, useParams } from "react-router-dom";
import { Path } from "../Path";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Image from "@mui/icons-material/Image";
import PictureAsPdf from "@mui/icons-material/PictureAsPdf";
import { formatBytes } from "../rules/formatBytes";

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/pitch2table-core").Sellsheet} props.item
 * @param {number} props.filteredIndex
 * @param {number} props.filteredCount
 */
export const SellSheetListEntry = ({ item, filteredIndex, filteredCount }) => {
  const params = useParams();
  const navigate = useNavigate();

  const selected = params.attachmentId === item.id;

  const onClickHandler = () => {
    navigate(Path.game(item.gameId).sellsheet(item.id));
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemText
        primary={
          <Stack direction="row">
            <Box pl={0} pr={1}>
              {item.mimeType === "application/pdf" ? <PictureAsPdf /> : <Image />}
            </Box>
            <Typography variant="inherit">
              v{filteredCount - filteredIndex} ({formatBytes(item.bytes, 1)})
            </Typography>
          </Stack>
        }
        secondary={`Uploaded ${DateTime.fromMillis(item.uploadedTimestamp).toFormat("h:mm a, DDD")}`}
      />
    </ListItemButton>
  );
};

export const NoSellSheets = () => (
  <NoItemsFound>
    <Alert severity="info">
      Here you can upload your sell sheets. As you pitch and get feedback from publishers you will evolve your
      sellsheet. In the publisher interest section, you can record what version you have sent to each publisher.
    </Alert>
  </NoItemsFound>
);
