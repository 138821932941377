import React from "react";
import { ListView } from "../../core/ListView";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { NoItemsFound } from "../../publisher/NoItemsFound";
import { Path } from "../../Path";
import { useNavigate, useParams } from "react-router-dom";
import { s2GameId } from "@seabrookstudios/types";
import { InviteDesignerToGame } from "./InviteDesignerToGame";
import { UserName } from "../../users/UserName";
import Typography from "@mui/material/Typography";
import { useUserProfile } from "../../user/queries";
import { canSendEmails } from "../../contacts/model";
import Alert from "@mui/material/Alert";
import { AlwaysOnAppLink } from "../../styleguide/AppLink";
import Box from "@mui/material/Box";

/**
 * @type {Record<import("@seabrookstudios/pitch2table-core").DesignerRole, string>}
 */
const DesignerRole = {
  owner: "Owner",
  codesigner: "Co-designer",
};

/**
 * @param {Object} props
 * @param {GameDesignersIncludingPending} props.designer
 */
const DesignerListEntry = ({ designer }) => {
  const params = useParams();
  const navigate = useNavigate();

  const selected = params.userId === designer.id;

  const onClickHandler = () => {
    navigate(Path.game(s2GameId(`${params.gameId}`)).designer(designer.id));
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemText
        primary={<UserName userId={designer.id} />}
        secondary={designer.pending ? "Invited" : DesignerRole[designer.role] || "Owner"}
      />
    </ListItemButton>
  );
};

const UpdateProfile = () => (
  <Box p={1}>
    <Alert severity="warning">
      <AlwaysOnAppLink to={Path.settings().category("about-you")}>Your profile is not complete.</AlwaysOnAppLink> Once
      you have completed it, you will be able to invite co-designers.
    </Alert>
  </Box>
);

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {GameDesignersIncludingPending[]} props.designers
 * @param {import("react-router-dom").To} props.back
 */
export const DesignerList = ({ gameId, designers = [], back }) => {
  const { data: profile } = useUserProfile();

  const showMissingProfileInformation = !canSendEmails(profile);

  const Action = () => <InviteDesignerToGame disabled={showMissingProfileInformation} gameId={gameId} />;

  return (
    <ListView
      title="Designers"
      Filters={null}
      Action={Action}
      back={back}
      EmptyList={() => (
        <NoItemsFound>
          <Typography align="center">No designers found.</Typography>
        </NoItemsFound>
      )}
      shown={designers.length}
      neverEmpty={false}
    >
      {showMissingProfileInformation && <UpdateProfile />}
      {designers.map((designer) => (
        <DesignerListEntry key={designer.id.toString()} designer={designer} />
      ))}
    </ListView>
  );
};
