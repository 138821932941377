import React from "react";
import { useGameContracts, useGameRoyalties } from "@seabrookstudios/pitch2table";
import { useGameIdFromRoute, useRoyaltyIdFromRoute } from "../WithGameIdFromRoute";
import { idExtractor, useMakeListAndDetail, useMakeListBuilder } from "../../core/useMakeLayout";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import { RoyaltyFromRoute } from "./RoyaltyFromRoute";
import AddRoyaltyAction from "./AddRoyaltyAction";
import { useUserProfile } from "../../user/queries";
import { Path } from "../../Path";
import { useNavigate } from "react-router-dom";
import { preferredCurrencySet } from "../../royalties/preferredCurrencySet";
import { NoRoyalties } from "../../royalties/NoRoyalties";
import { RoyaltyTotals } from "../../royalties/RoyaltyTotals";
import { RoyaltyStatusIcon } from "../../royalties/RoyaltyStatusIcon";
import { RoyaltyAmount } from "../../royalties/RoyaltyAmount";
import { RoyaltyDate } from "../../royalties/RoyaltyDate";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp} props.item
 */
const ListEntry = ({ gameId, item }) => {
  const { data: contracts = [] } = useGameContracts(gameId);
  const contract = contracts.find((c) => c.id === item.contractId);

  const royaltyId = useRoyaltyIdFromRoute();
  const navigate = useNavigate();

  const selected = royaltyId === item.id;

  const onClickHandler = () => {
    navigate(Path.game(gameId).royalty(item.id));
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <RoyaltyStatusIcon item={item} />
      <ListItemText
        primary={<RoyaltyAmount item={item} />}
        secondary={contract ? contract.name : "Not linked to a contract"}
      />
      <RoyaltyDate item={item} />
    </ListItemButton>
  );
};

export const RoyaltiesListAndDetail = () => {
  const gameId = useGameIdFromRoute();
  const { data: royalties = [] } = useGameRoyalties(gameId);
  const { data: profile } = useUserProfile();

  const Action = () => <AddRoyaltyAction id="add-royalty" gameId={gameId} />;

  const useWrappedQuery = () => useGameRoyalties(gameId);

  const useMakeList = useMakeListBuilder(
    (props) => <ListEntry gameId={gameId} {...props} />,
    idExtractor,
    "Royalties",
    NoRoyalties,
    preferredCurrencySet(profile) ? Action : undefined,
    () => <RoyaltyTotals royalties={royalties} />,
    Path.game(gameId).link()
  );

  const ListAndDetail = useMakeListAndDetail(useWrappedQuery, [], "royalties", useMakeList, RoyaltyFromRoute);

  return <ListAndDetail />;
};
