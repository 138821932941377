import React, { useContext, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SmartTextField } from "../games/SmartTextField";
import { enqueueSnackbar } from "notistack";
import { CachedTokenContext } from "@seabrookstudios/auth";

/**
 * @param {object} props
 * @param {string} props.initialValue
 * @param {string} props.id
 * @param {string} props.label
 * @param {string} props.helperText
 * @param {boolean} [props.allowsBlank]
 * @param {Function} props.updateFunction
 * @param {import("@tanstack/react-query").QueryKey} props.queryKey
 */
export const UpdatableNameTextField = ({
  initialValue,
  allowsBlank = false,
  label,
  id,
  helperText,
  updateFunction,
  queryKey,
  ...props
}) => {
  const [saved, setSaved] = useState(initialValue);
  const [lastSavedValue, setLastSaved] = useState(initialValue);
  const token = useContext(CachedTokenContext);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (newValue) => {
      const response = await updateFunction(token, newValue);
      return response;
    },
    onSuccess: (newValue) => {
      setLastSaved(newValue);
      enqueueSnackbar(`${label} updated.`, { variant: "success" });
      queryClient.invalidateQueries({ queryKey });
    },
  });

  /**
   * @param {string} newValue
   */
  const onChange = async (newValue) => {
    setSaved(newValue);
  };

  const saveChanges = () => {
    if (saved === lastSavedValue) {
      return;
    }
    if (!allowsBlank && saved.length === 0) {
      return;
    }

    mutation.mutate(saved);
  };

  const onKeyDownHandler = (ev) => {
    if (ev.key !== "Enter") {
      return;
    }

    saveChanges();
  };

  return (
    <SmartTextField
      id={id}
      label={label}
      helperText={helperText}
      initialValue={initialValue}
      onChange={onChange}
      onKeyDown={onKeyDownHandler}
      onBlur={saveChanges}
      {...props}
    />
  );
};
