import React, { useState } from "react";
import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import { useMyGames } from "@seabrookstudios/pitch2table";
import { useHotkeys } from "react-hotkeys-hook";
import List from "@mui/material/List";
import { GameListEntry, GameListEntry2 } from "./games/ListItem";

/**
 * @param {object} props
 * @param {import("react").ReactNode} props.children
 */
export const QuickSearch = ({ children }) => {
  const { data: games = [] } = useMyGames();
  const [showIssueCreatorModal, setShowIssueCreatorModal] = useState(false);
  const [filter, setFilter] = useState("");

  useHotkeys("mod+k", () => setShowIssueCreatorModal(true));
  useHotkeys("escape", () => setShowIssueCreatorModal(false));

  if (!showIssueCreatorModal) {
    return children;
  }

  const updateFilter = (e) => {
    setFilter(e.target.value);
  };

  return (
    <>
      {children}
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={showIssueCreatorModal}
        onClick={() => setShowIssueCreatorModal(false)}
      >
        <Box width="66%">
          <Card>
            <Box p={2}>
              <TextField fullWidth label="Search" id="search" value={filter} autoFocus onChange={updateFilter} />
              {filter.length > 0 && (
                <List>
                  {games
                    .filter((g) => g.name.toLocaleLowerCase().startsWith(filter.toLocaleLowerCase()))
                    .slice(0, 5)
                    .map((game) => (
                      <GameListEntry2 item={game} />
                    ))}
                </List>
              )}
            </Box>
          </Card>
        </Box>
      </Backdrop>
    </>
  );
};
