import React from "react";
import { FullScreenDialog } from "../core/FullScreenDialog";
import { PremiumUploadSellSheetComponent, UploadSellsheetComponent } from "./UploadSellsheetComponent";
import { useMyEntitlements } from "@seabrookstudios/pitch2table";
import Typography from "@mui/material/Typography";

export default function AddSellsheetFullScreenDialog({ gameId }) {
  const { data: entitlements } = useMyEntitlements();

  const hasLargeFileUploads = entitlements && entitlements.hasLargeFileUploads;

  return (
    <FullScreenDialog title="Add Sellsheet" canSave={true}>
      {hasLargeFileUploads ? (
        <PremiumUploadSellSheetComponent gameId={gameId} />
      ) : (
        <UploadSellsheetComponent gameId={gameId} />
      )}
      <Typography variant="body2" textAlign="right" color="text.secondary">
        Maximum file size: {hasLargeFileUploads ? "500MB" : "5MB"}
      </Typography>
    </FullScreenDialog>
  );
}
