import React from "react";
import {
  useDeletePlayTest,
  useGamePlayTest,
  useGamePlayTestMetaDataEntry,
  useUpdatePlayTest,
  useUpdatePlayTestMetaData,
} from "@seabrookstudios/pitch2table";
import Card from "@mui/material/Card";
import { useGameIdFromRoute, usePlayTestIdFromRoute, usePlayTestMetaIdFromRoute } from "../games/WithGameIdFromRoute";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import Box from "@mui/material/Box";
import { useDocumentTitle } from "usehooks-ts";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import CardHeader from "@mui/material/CardHeader";
import Delete from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { Path } from "../Path";
import { EditableListItemNumberWithUndefined, EditableListItemText } from "../core/EditableListItemText";
import Refresh from "@mui/icons-material/Refresh";
import Stack from "@mui/material/Stack";
import { EditableSwitch } from "../core/EditableSwitch";
import { EditableDate } from "../core/EditableDate";
import { AudienceSelect } from "./AudienceSelect";
import { MediumSelect } from "./MediumSelect";
import { RulesVersionSelect } from "../games/publisher-interest/PublisherInterestRulesSentSelect";
import { TopicDisplay } from "../message-board/TopicFromRoute";
import { AddMessageToPlayTest } from "../message-board/AddTopic";
import { MetadataTypeSelect } from "./MetadataTypeSelect";
import Archive from "@mui/icons-material/Archive";
import MoveToInbox from "@mui/icons-material/MoveToInbox";
import Alert from "@mui/material/Alert";
import { ReadOnlyScoringComponent } from "./Scoring";
import { makeOnSuccess } from "../core/toast";
import Tooltip from "@mui/material/Tooltip";
import { CombinedScoreResults, TrackGameLevelResult } from "./Chart";
import Divider from "@mui/material/Divider";

export const PlayTestFromRoute = () => {
  const gameId = useGameIdFromRoute();
  const playTestId = usePlayTestIdFromRoute();
  const { data: playTest, refetch, isRefetching } = useGamePlayTest(gameId, playTestId);
  const updatePlayTest = useUpdatePlayTest(gameId, playTestId, makeOnSuccess("Play test updated."));
  const deletePlayTest = useDeletePlayTest(gameId, playTestId, makeOnSuccess("Play test deleted."));
  const navigate = useNavigate();

  useDocumentTitle(playTest ? "Play Test" : "Pitch2Table");

  if (!playTestId) {
    return <EmptyDetailCard text="Select a play test from the list on the left." />;
  }

  const onDeleteHandler = async () => {
    await deletePlayTest.mutate();
    navigate(Path.game(gameId).tests());
  };

  const onEditDuration = async (duration) => {
    await updatePlayTest.mutateAsync({
      duration,
      audience: undefined,
      complete: undefined,
      date: undefined,
      ignore: undefined,
      medium: undefined,
      rulesVersion: undefined,
      body: undefined,
      outcomes: undefined,
      nonPlayerOutcomes: undefined,
    });
  };

  const onEditIgnore = async (ignore) => {
    await updatePlayTest.mutateAsync({
      duration: undefined,
      audience: undefined,
      complete: undefined,
      date: undefined,
      ignore,
      medium: undefined,
      rulesVersion: undefined,
      body: undefined,
      outcomes: undefined,
      nonPlayerOutcomes: undefined,
    });
  };

  const onEditComplete = async (complete) => {
    await updatePlayTest.mutateAsync({
      duration: undefined,
      audience: undefined,
      complete,
      date: undefined,
      ignore: undefined,
      medium: undefined,
      rulesVersion: undefined,
      body: undefined,
      outcomes: undefined,
      nonPlayerOutcomes: undefined,
    });
  };

  const onEditDate = async (date) => {
    await updatePlayTest.mutateAsync({
      duration: undefined,
      audience: undefined,
      complete: undefined,
      date,
      ignore: undefined,
      medium: undefined,
      rulesVersion: undefined,
      body: undefined,
      outcomes: undefined,
      nonPlayerOutcomes: undefined,
    });
  };

  const onEditAudience = async (audience) => {
    await updatePlayTest.mutateAsync({
      duration: undefined,
      audience,
      complete: undefined,
      date: undefined,
      ignore: undefined,
      medium: undefined,
      rulesVersion: undefined,
      body: undefined,
      outcomes: undefined,
      nonPlayerOutcomes: undefined,
    });
  };

  const onEditMedium = async (medium) => {
    await updatePlayTest.mutateAsync({
      duration: undefined,
      audience: undefined,
      complete: undefined,
      date: undefined,
      ignore: undefined,
      medium,
      rulesVersion: undefined,
      body: undefined,
      outcomes: undefined,
      nonPlayerOutcomes: undefined,
    });
  };

  const onEditRulesVersion = async (rulesVersion) => {
    await updatePlayTest.mutateAsync({
      duration: undefined,
      audience: undefined,
      complete: undefined,
      date: undefined,
      ignore: undefined,
      medium: undefined,
      rulesVersion,
      body: undefined,
      outcomes: undefined,
      nonPlayerOutcomes: undefined,
    });
  };

  if (!playTest) {
    return (
      <Box>
        <Card elevation={1} square={false}>
          <CircularProgress />
        </Card>
      </Box>
    );
  }

  return (
    <Box>
      <Card elevation={1} square={false}>
        <CardHeader
          action={
            <Stack direction="row">
              <IconButton onClick={onDeleteHandler}>
                {deletePlayTest.isPending ? <CircularProgress size={16} /> : <Delete />}
              </IconButton>
              <IconButton onClick={() => refetch()}>
                {isRefetching ? <CircularProgress size={16} /> : <Refresh />}
              </IconButton>
            </Stack>
          }
        />
        <List>
          <Stack direction="row">
            <ListItem>
              <EditableDate
                isBusy={updatePlayTest.isPending}
                onSave={onEditDate}
                timestamp={playTest.date}
                title="Date of Play Test"
                disableFuture
                popupTitle="date of play test"
              />
            </ListItem>
            <ListItem>
              <EditableListItemNumberWithUndefined
                primary="Duration (minutes)"
                secondary={playTest.duration}
                helperText="How long did the game go for (in minutes)?"
                onSave={onEditDuration}
                isBusy={updatePlayTest.isPending}
              />
            </ListItem>
          </Stack>
          <ListItem>
            <AudienceSelect
              value={playTest.audience}
              onChange={onEditAudience}
              helperText="Broadly speaking, how would you classify the play testers?"
            />
          </ListItem>
          <ListItem>
            <MediumSelect
              value={playTest.medium}
              onChange={onEditMedium}
              helperText="Is this an in-person or virtual play test?"
            />
          </ListItem>
          <ListItem>
            <RulesVersionSelect
              label="Rules Version for Test"
              gameId={gameId}
              value={playTest.rulesVersion}
              onChange={onEditRulesVersion}
              helperText="The rules version used for this play test. Use the comments section below to note impromptu rule changes."
            />
          </ListItem>
          <Stack direction="row">
            <ListItem>
              <EditableSwitch
                initialValue={playTest.complete}
                title="Completed"
                offLabel="This play test was not completed."
                onLabel="This play test is complete."
                onUpdate={onEditComplete}
              />
            </ListItem>
            <ListItem>
              <EditableSwitch
                initialValue={playTest.ignore}
                title="Include in statistics"
                onLabel="This play test will be included in statistics."
                offLabel="This play test won't be used for statistics."
                onUpdate={onEditIgnore}
              />
            </ListItem>
          </Stack>
        </List>
      </Card>
      <Box pt={1}>
        <Card>
          <ReadOnlyScoringComponent
            outcomes={playTest.outcomes}
            gameId={gameId}
            playTestId={playTestId}
            nonPlayerOutcomes={playTest.nonPlayerOutcomes}
          />
        </Card>
      </Box>
      <Box pt={1}>
        {playTest.topicId ? (
          <TopicDisplay
            gameId={gameId}
            topicId={playTest.topicId}
            hideType
            hideDate
            customTitle="Play Test Notes"
            hideTitle={false}
          />
        ) : (
          <AddMessageToPlayTest gameId={gameId} playTestId={playTestId} />
        )}
      </Box>
    </Box>
  );
};

export const PlayTestMetadataFromRoute = () => {
  const gameId = useGameIdFromRoute();
  const metaId = usePlayTestMetaIdFromRoute();
  const { data: metadata, refetch, isRefetching } = useGamePlayTestMetaDataEntry(gameId, metaId);
  const updatePlayTest = useUpdatePlayTestMetaData(gameId, metaId);

  useDocumentTitle(metadata ? metadata.name : "Pitch2Table");

  if (!metaId) {
    return <EmptyDetailCard text="Select a play test from the list on the left." />;
  }

  const onArchiveHandler = async () => {
    if (!metadata) {
      return;
    }

    await updatePlayTest.mutateAsync({
      name: undefined,
      order: undefined,
      type: undefined,
      archived: !metadata.archived,
    });
  };

  const onEditName = async (name) => {
    await updatePlayTest.mutateAsync({
      name,
      order: undefined,
      type: undefined,
      archived: undefined,
    });
  };

  const onEditType = async (type) => {
    await updatePlayTest.mutateAsync({
      name: undefined,
      order: undefined,
      type,
      archived: undefined,
    });
  };

  if (!metadata) {
    return (
      <Box>
        <Card elevation={1} square={false}>
          <CircularProgress />
        </Card>
      </Box>
    );
  }

  const Icon = metadata.archived ? MoveToInbox : Archive;

  return (
    <Box>
      <Card elevation={1} square={false}>
        <CardHeader
          action={
            <Stack direction="row">
              <Tooltip title={metadata.archived ? "Restore from archive" : "Archive"}>
                <IconButton onClick={onArchiveHandler}>
                  {updatePlayTest.isPending ? <CircularProgress size={16} /> : <Icon />}
                </IconButton>
              </Tooltip>
              <Tooltip title="Refresh">
                <IconButton onClick={() => refetch()}>
                  {isRefetching ? <CircularProgress size={16} /> : <Refresh />}
                </IconButton>
              </Tooltip>
            </Stack>
          }
        />
        <List>
          {metadata.archived && (
            <ListItem>
              <Alert severity="info">
                Archived! This tracking variable can no longer be added to play tests. It will remain present in all
                play tests that it is currently recorded in.
              </Alert>
            </ListItem>
          )}
          <ListItem>
            <EditableListItemText
              primary="Name"
              secondary={metadata.name}
              onSave={onEditName}
              isBusy={updatePlayTest.isPending}
              helperText="The name of the tracking variable e.g. score, trade action, etc"
            />
          </ListItem>
          <ListItem>
            <MetadataTypeSelect
              value={metadata.type}
              onChange={onEditType}
              helperText="How is this value tracked, per game, per player, part of the score or is the score?"
            />
          </ListItem>
        </List>
        <Divider />
        {metadata.type === "SCORE" && <CombinedScoreResults gameId={gameId} metaId={metadata.id} />}
        {metadata.type === "PER_GAME" && <TrackGameLevelResult gameId={gameId} metaId={metadata.id} />}
        {metadata.type === "PART_OF_SCORE" && <CombinedScoreResults gameId={gameId} metaId={metadata.id} />}
        {metadata.type === "PER_PLAYER" && <CombinedScoreResults gameId={gameId} metaId={metadata.id} />}
      </Card>
    </Box>
  );
};
