import React, { useContext } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { enqueueSnackbar } from "notistack";
import endpoints from "../endpoints";
import { s2Url } from "@seabrookstudios/types";
import { CachedTokenContext } from "@seabrookstudios/auth";
import { usePreSignedUploadImage, useUploadImage } from "../users/queries";
import { useQueryClient } from "@tanstack/react-query";
import { GameById, GameSellsheets } from "@seabrookstudios/pitch2table";

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginImageValidateSize);
registerPlugin(FilePondPluginImageCrop);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageResize);
registerPlugin(FilePondPluginImageTransform);
registerPlugin(FilePondPluginFileValidateSize);

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const UploadSellsheetComponent = ({ gameId }) => {
  const token = useContext(CachedTokenContext);
  const queryClient = useQueryClient();

  const onFileUploadSuccess = () => {
    enqueueSnackbar("Latest sellsheet uploaded", { variant: "success" });
    queryClient.invalidateQueries({ queryKey: GameSellsheets(gameId) });
    queryClient.invalidateQueries({ queryKey: GameById(gameId) });
  };

  const onFileUploadFailure = () => {
    enqueueSnackbar("An error occurred uploading the sellsheet", { variant: "error" });
  };

  const { games } = endpoints();
  const url = games().sellSheet(gameId);
  const uploadImage = useUploadImage(s2Url(url), token, "sellsheet", onFileUploadSuccess, onFileUploadFailure);

  return (
    <FilePond
      allowMultiple={false}
      allowReplace={false}
      allowRevert={false}
      allowProcess={false}
      credits={false}
      instantUpload={true}
      maxFiles={1}
      labelIdle={'Drag & Drop your sellsheet or <span class="filepond--label-action"> Browse </span>'}
      maxFileSize="5MB"
      server={{
        process: uploadImage,
      }}
    />
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const PremiumUploadSellSheetComponent = ({ gameId }) => {
  const token = useContext(CachedTokenContext);
  const queryClient = useQueryClient();

  const onFileUploadSuccess = () => {
    enqueueSnackbar("Latest sell sheet uploaded", { variant: "success" });
    queryClient.invalidateQueries({ queryKey: GameSellsheets(gameId) });
    queryClient.invalidateQueries({ queryKey: GameById(gameId) });
  };

  const onFileUploadFailure = () => {
    enqueueSnackbar("An error occurred uploading the sell sheet", { variant: "error" });
  };

  const { games } = endpoints();
  const startUrl = games().sellSheetStart(gameId);
  const finishUrl = games().sellSheetFinish(gameId);
  const uploadImage = usePreSignedUploadImage(startUrl, finishUrl, token, onFileUploadSuccess, onFileUploadFailure);

  return (
    <FilePond
      allowMultiple={false}
      allowReplace={false}
      allowRevert={false}
      allowProcess={false}
      credits={false}
      instantUpload={true}
      maxFiles={1}
      labelIdle={'Drag & Drop your sellsheet or <span class="filepond--label-action"> Browse </span>'}
      maxFileSize="500MB"
      server={{
        process: uploadImage,
      }}
    />
  );
};
