import React from "react";
import Chip from "@mui/material/Chip";

/**
 * @template T
 * @param {object} props
 * @param {Filter<T>[]} props.filters
 * @param {string} props.filter
 * @param {(label: string) => void} props.onFilterClicked
 */
export const FilterList = ({ filters = [], filter, onFilterClicked }) => {
  return filters.map(({ Icon, label, count }) => {
    const onChipClicked = () => onFilterClicked(label);
    return (
      <Chip
        id={label}
        icon={Icon && <Icon />}
        key={label}
        label={`${label} (${count})`}
        onClick={onChipClicked}
        variant={label === filter ? "filled" : "outlined"}
        color={label === filter ? "secondary" : "default"}
      />
    );
  });
};
