import React from "react";
import { DateTime } from "luxon";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { useGameRules } from "@seabrookstudios/pitch2table";
import { rulesVersion } from "../model";
import { AlwaysOnAppLink } from "../../styleguide/AppLink";
import { Path } from "../../Path";

const Label = "Rules Sent";

const formatAttachmentDate = (timestamp) => DateTime.fromMillis(timestamp).toFormat("h:mm a, DDD");

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").AttachmentId|undefined} props.value
 * @param {(newValue: import("@seabrookstudios/pitch2table-core").AttachmentId) => void} props.onChange
 * @param {boolean} [props.disabled]
 */
export const PublisherInterestRulesSentSelect = ({ gameId, value, onChange, disabled = false }) => {
  const { data: rules = [], isLoading } = useGameRules(gameId);

  /**
   * @param {*} ev
   */
  const onChangeHandler = (ev) => {
    onChange(ev.target.value);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <FormControl sx={{ width: "100%" }} margin="normal" disabled={disabled}>
      <InputLabel id="publisher-interest-rules-sent">{Label}</InputLabel>
      <Select
        labelId="publisher-interest-rules-sent"
        value={rules.length === 0 ? "no-rules" : value}
        onChange={onChangeHandler}
        placeholder={Label}
        label={Label}
        input={<OutlinedInput label={Label} />}
        disabled={rules.length === 0}
      >
        {rules.map((rule, index) => {
          return (
            <MenuItem value={rule.id}>
              {formatAttachmentDate(rule.uploadedTimestamp)} - {rulesVersion(rules, index)}
            </MenuItem>
          );
        })}
        {rules.length === 0 && (
          <MenuItem value="no-rules">Upload at least one rules version to use this feature.</MenuItem>
        )}
      </Select>
      <FormHelperText>
        The rules version sent to the publisher.{" "}
        <AlwaysOnAppLink to={Path.game(gameId).rules()}>Upload new rules</AlwaysOnAppLink>
      </FormHelperText>
    </FormControl>
  );
};

/**
 * @param {object} props
 * @param {string|undefined} [props.label]
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").AttachmentId|undefined} props.value
 * @param {(newValue: import("@seabrookstudios/pitch2table-core").AttachmentId) => void} props.onChange
 * @param {string} props.helperText
 * @param {boolean} [props.disabled]
 */
export const RulesVersionSelect = ({ gameId, label, value, onChange, helperText, disabled = false }) => {
  const { data: rules = [], isLoading } = useGameRules(gameId);

  /**
   * @param {*} ev
   */
  const onChangeHandler = (ev) => {
    onChange(ev.target.value);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <FormControl sx={{ width: "100%" }} margin="normal" disabled={disabled}>
      <InputLabel id="rules-version-select">{label || Label}</InputLabel>
      <Select
        labelId="rules-version-select"
        value={rules.length === 0 ? "no-rules" : value}
        onChange={onChangeHandler}
        placeholder={label || Label}
        label={label || Label}
        input={<OutlinedInput label={label || Label} />}
        disabled={rules.length === 0}
      >
        {rules.map((rule, index) => {
          return (
            <MenuItem value={rule.id}>
              {formatAttachmentDate(rule.uploadedTimestamp)} - {rulesVersion(rules, index)}
            </MenuItem>
          );
        })}
        {rules.length === 0 && (
          <MenuItem value="no-rules">Upload at least one rules version to use this feature.</MenuItem>
        )}
      </Select>
      <FormHelperText>
        {helperText} <AlwaysOnAppLink to={Path.game(gameId).rules()}>Upload new rules</AlwaysOnAppLink>
      </FormHelperText>
    </FormControl>
  );
};
