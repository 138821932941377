import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import Tooltip from "@mui/material/Tooltip";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import { Features } from "../games/features";
import MenuItem from "@mui/material/MenuItem";

/**
 *
 * @param {object} props
 * @param {string|undefined} [props.id]
 * @param {string} props.tooltip
 * @param {string} props.label
 * @param {Function} props.onClick
 * @param {import("react").ReactNode} props.children
 * @param {boolean} props.selected
 * @param {boolean} [props.iconOnly]
 * @returns
 */
export const SideMenuOption = ({ id = undefined, tooltip, label, onClick, children, selected, iconOnly = false }) => {
  const onClickHandler = () => {
    onClick();
  };

  if (Features.BaseCampLayout) {
    return (
      <Tooltip title={tooltip}>
        <Box id={id}>
          <IconButton onClick={onClickHandler} sx={{ display: { xs: "block", md: "none" } }}>
            {children}
          </IconButton>
          <MenuItem onClick={onClickHandler} selected={selected} sx={{ display: { xs: "none", md: "flex" } }}>
            <ListItemIcon>{children}</ListItemIcon>
            {!iconOnly && <ListItemText primary={label} />}
          </MenuItem>
        </Box>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tooltip}>
      <Box>
        <IconButton onClick={onClickHandler} sx={{ display: { xs: "block", md: "none" } }}>
          {children}
        </IconButton>
        <ListItemButton onClick={onClickHandler} selected={selected} sx={{ display: { xs: "none", md: "flex" } }}>
          <ListItemIcon>{children}</ListItemIcon>
          <ListItemText primary={label} />
        </ListItemButton>
      </Box>
    </Tooltip>
  );
};
