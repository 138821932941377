import React from "react";
import Link from "@mui/material/Link";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ArrowForwardOutlined from "@mui/icons-material/ArrowForwardOutlined";
import { Link as RouterLink } from "react-router-dom";

/**
 * @param {object} props
 * @param {import("react-router-dom").To} props.link
 * @param {import("react").ReactNode} props.primary
 * @param {import("react").ReactNode} props.secondary
 */
export const MenuItem = ({ link, primary, secondary }) => {
  return (
    <ListItem>
      <ListItemText primary={primary} secondary={secondary} />
      <Link to={link} component={RouterLink}>
        <ListItemIcon>
          <ListItemButton>
            <ArrowForwardOutlined />
          </ListItemButton>
        </ListItemIcon>
      </Link>
    </ListItem>
  );
};
