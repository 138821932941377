import { useParams } from "react-router-dom";
import { s2LaneId, s2PublisherId, s2RoyaltyEventId } from "../util/type-conversion";
import { s2GameId, s2UserId } from "@seabrookstudios/types";

/**
 * @returns {import("@seabrookstudios/types").GameId}
 */
export const useGameIdFromRoute = () => {
  return s2GameId(useParams().gameId || "");
};

/**
 * @returns {import("@seabrookstudios/pitch2table-core").AttachmentId}
 */
export const useAttachmentIdFromRoute = () => {
  return /** @type {import("@seabrookstudios/pitch2table-core").AttachmentId} */ (useParams().attachmentId || "");
};

/**
 * @returns {import("@seabrookstudios/pitch2table-core").PublisherId}
 */
export const usePublisherIdFromRoute = () => {
  return s2PublisherId(useParams().publisherId || "");
};

/**
 * @returns {string}
 */
export const useLaneIdFromRoute = () => {
  return s2LaneId(useParams().laneId || "");
};

/**
 * @returns {import("@seabrookstudios/types").UserId}
 */
export const useUserIdIdFromRoute = () => {
  return s2UserId(useParams().userId || "");
};

/**
 * @returns {import("@seabrookstudios/pitch2table-core").RoyaltyEventId}
 */
export const useRoyaltyIdFromRoute = () => {
  return s2RoyaltyEventId(useParams().royaltyId || "");
};

/**
 * @returns {import("@seabrookstudios/pitch2table-core").TopicId}
 */
export const useTopicIdFromRoute = () => {
  return /** @type {import("@seabrookstudios/pitch2table-core").TopicId} */ (useParams().topicId || "");
};

/**
 * @returns {import("@seabrookstudios/pitch2table-core").PlayTestId}
 */
export const usePlayTestIdFromRoute = () => {
  return /** @type {import("@seabrookstudios/pitch2table-core").PlayTestId} */ (useParams().playTestId || "");
};

/**
 * @returns {import("@seabrookstudios/pitch2table-core").PlayTestMetadataComponentId}
 */
export const usePlayTestMetaIdFromRoute = () => {
  return /** @type {import("@seabrookstudios/pitch2table-core").PlayTestMetadataComponentId} */ (
    useParams().metaId || ""
  );
};
