import React from "react";
import { DateTime } from "luxon";
import { LineChart } from "@mui/x-charts/LineChart";
import Box from "@mui/material/Box";
import { formatPlace, useGameLevelResults, useMaxPlayers, useScoreParts, useScoreResults2 } from "./model";
import { BarChart } from "@mui/x-charts/BarChart";
import { useGamePlayTestMetaData } from "@seabrookstudios/pitch2table";
import Typography from "@mui/material/Typography";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestMetadataComponentId} props.metaId
 */
export const SingleTrackedDataChart = ({ gameId, metaId }) => {
  const maxPlayers = useMaxPlayers(gameId, metaId);
  const data = useScoreResults2(gameId, metaId);

  return (
    <Box>
      <LineChart
        skipAnimation
        dataset={data}
        xAxis={[
          {
            dataKey: "no",
            valueFormatter: (v) => {
              if (!data[v]) {
                return "";
              }

              return DateTime.fromMillis(data[v].date).toFormat("D");
            },
          },
        ]}
        series={Array(maxPlayers)
          .fill(0)
          .map((_, i) => i + 1)
          .map((no) => ({
            label: formatPlace(no),
            dataKey: `${no}`,
            area: true,
            curve: "linear",
            showMark: false,
          }))}
        // onAxisClick={(_e, params) => {
        //   if (!params) {
        //     return;
        //   }
        //   navigate(Path.game(gameId).test(data[params.dataIndex].id));
        // }}
        yAxis={[{ min: 0 }]}
        slotProps={{
          legend: {
            direction: "row",
            position: { vertical: "top", horizontal: "middle" },
          },
        }}
        height={500}
      />
      <Box p={1}>
        <Typography variant="body2" color="text.secondary" fontStyle="italic">
          Only play tests that have a value for this data are included in the chart above.
        </Typography>
      </Box>
    </Box>
  );
};

export const CombinedScoreResults = SingleTrackedDataChart;

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestMetadataComponentId} props.metaId
 */
export const TrackGameLevelResult = ({ gameId, metaId }) => {
  const resultPerGame = useGameLevelResults(gameId, metaId);

  return (
    <Box>
      <BarChart
        skipAnimation
        series={[{ data: resultPerGame }]}
        slotProps={{
          legend: {
            direction: "row",
            position: { vertical: "top", horizontal: "middle" },
          },
        }}
        yAxis={[{ min: 0, label: "Count" }]}
        height={500}
        bottomAxis={null}
      />
      <Box p={1}>
        <Typography variant="body2" color="text.secondary" fontStyle="italic">
          Only play tests that have a value are included in the chart above.
        </Typography>
      </Box>
    </Box>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {boolean} props.proportional
 * @param {boolean} props.winnersOnly
 */
export const ScoreCompositionChart = ({ gameId, proportional, winnersOnly }) => {
  const { data: metadata = [] } = useGamePlayTestMetaData(gameId);
  const scoreParts = metadata.filter((m) => m.type === "PART_OF_SCORE");

  const byPosition = useScoreParts(
    gameId,
    scoreParts.map((m) => m.id),
    winnersOnly
  );

  return (
    <Box>
      <BarChart
        skipAnimation
        dataset={byPosition}
        series={scoreParts.map((m) => ({
          dataKey: m.id,
          label: m.name,
          stack: "none",
          stackOffset: proportional ? "expand" : "none",
          scaleType: "band",
        }))}
        yAxis={[{ min: 0 }]}
        slotProps={{
          legend: {
            direction: "row",
            position: { vertical: "top", horizontal: "middle" },
          },
        }}
        height={500}
      />
      <Box p={1}>
        <Typography variant="body2" color="text.secondary" fontStyle="italic">
          Only play tests that have at least one score part are included in the chart above.
        </Typography>
      </Box>
    </Box>
  );
};
