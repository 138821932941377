import React from "react";
import { Path } from "../Path";
import { useGamePlayTests } from "@seabrookstudios/pitch2table";
import { MenuItem } from "../core/MenuItem";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const PlayTestsMenuItem = ({ gameId }) => {
  const { data: playTests = [] } = useGamePlayTests(gameId);

  return (
    <MenuItem
      primary="Play Tests"
      secondary={playTests.length === 1 ? `1 play test` : `${playTests.length} play tests`}
      link={Path.game(gameId).tests()}
    />
  );
};
