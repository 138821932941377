import React, { useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

/**
 * @param {object} props
 * @param {number} props.initialValue
 * @param {(value: number) => void} props.onChange
 * @param {string} props.label
 * @param {string} props.helperText
 */
export const SmartDateField = ({ initialValue = DateTime.now().toMillis(), onChange, label, helperText }) => {
  const [dueDateObj, setDueDateObj] = useState(DateTime.fromMillis(initialValue));

  /**
   * @param {DateTime} componentDate
   */
  const onUpdateDate = (componentDate) => {
    setDueDateObj(componentDate);
    onChange(componentDate.toMillis());
  };

  return (
    <Stack direction="column">
      <DatePicker defaultValue={dueDateObj} label={label} onChange={onUpdateDate} onAccept={onUpdateDate} />
      <Typography color="text.secondary" variant="caption" sx={{ marginTop: "3px", marginLeft: "14px" }}>
        {helperText}
      </Typography>
    </Stack>
  );
};
