import React from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import { alphabetically } from "@seabrookstudios/types";

const Label = "BG Stats Game";

/**
 * @param {object} props
 * @param {{id: string, name: string}[]} props.options
 * @param {string} props.value
 * @param {(ids: string) => void} props.onChange
 */
export const BgStatsGameSelect = ({ options = [], value, onChange }) => {
  const onChangeHandler = (event) => {
    onChange(event.target.value);
  };

  options.sort(alphabetically("name"));

  return (
    <FormControl sx={{ width: "100%" }} margin="normal">
      <InputLabel id="bg-stats-import-game-label">{Label}</InputLabel>
      <Select
        id="bg-stats-import-game"
        labelId="bg-stats-import-game-label"
        value={value}
        onChange={onChangeHandler}
        placeholder={Label}
        label={Label}
        input={<OutlinedInput label={Label} />}
      >
        {options.map((d) => (
          <MenuItem key={d.id} value={d.id}>
            <ListItemText primary={d.name} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        Select the source game from the BG Stats file. Make sure you select the correct game as it will take a long time
        to delete play tests uploaded to the wrong game.
      </FormHelperText>
    </FormControl>
  );
};
