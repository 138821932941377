import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

export const SmartTextField = ({ id, initialValue = "", onChange, helperText = "", ...props }) => {
  const [name, setName] = useState(initialValue);
  const [changed, setHasChanged] = useState(false);
  const [isValid, setValidStatus] = useState(true);

  const onChangeHandler = (ev) => {
    setHasChanged(true);
    setName(ev.target.value);
  };

  const validate = useCallback(() => {
    if (name.length === 0) {
      onChange("");
      setValidStatus(false);
      return false;
    }

    setValidStatus(true);
    onChange(name);
    return true;
  }, [setValidStatus, name, onChange]);

  useEffect(() => {
    if (!changed) {
      return;
    }

    validate();
  }, [name, validate, changed]);

  return (
    <TextField
      defaultValue={initialValue}
      fullWidth
      id
      type="text"
      margin="dense"
      {...props}
      error={!isValid}
      onChange={onChangeHandler}
      helperText={helperText}
    />
  );
};
