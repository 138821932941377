import React from "react";
import Alert from "@mui/material/Alert";
import { NoItemsFound } from "../publisher/NoItemsFound";
import Box from "@mui/material/Box";
import { PlayTestMetaData } from "./ListEntry";

export const NoPlayTests = () => {
  return (
    <Box>
      <PlayTestMetaData />
      <NoItemsFound>
        <Alert severity="info">
          Record your play tests here. All fields are optional. The more information you capture about through your
          tests the more we can provide trend analysis.
        </Alert>
      </NoItemsFound>
    </Box>
  );
};

export const NoPlayTestMetadata = () => {
  return (
    <NoItemsFound>
      <Alert severity="info">
        Track important aspects of your game to see how they change over time. This could be the overall score, the
        number of times a particular action occurs per game or whether a particular faction wins more often than not.
      </Alert>
    </NoItemsFound>
  );
};
