import React from "react";
import Add from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { background } from "../theme";
import DeleteForever from "@mui/icons-material/DeleteForever";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Paper from "@mui/material/Paper";

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} [props.id]
 * @param {string} [props.actionText]
 * @param {Function} [props.onSaveHandler]
 * @param {Function} [props.onDeleteHandler]
 * @param {boolean} [props.canSave]
 * @param {boolean} [props.isBusy]
 * @param {import("react").ReactNode} [props.children]
 * @param {import("react").ReactNode} [props.CustomControl]
 * @param {"flex-end"|"center"|"flex-start"} [props.iconAlign]
 * @param {(() => React.JSX.Element)|import("@mui/material/OverridableComponent").OverridableComponent<import("@mui/material").SvgIconTypeMap<{}, "svg">> & { muiName: string; }} [props.Icon]
 * @param {boolean} [props.textButton]
 * @param {boolean} [props.disabled]
 * @param {"small"|"medium"|"large"} [props.size]
 * @param {import("react").ReactNode} [props.DeleteIcon]
 * @param {string} [props.minWidth]
 */
export const FullScreenDialog = ({
  id = "add",
  isBusy = false,
  title,
  actionText,
  onSaveHandler,
  onDeleteHandler,
  canSave,
  children,
  iconAlign = "flex-end",
  Icon = () => <Add />,
  textButton = false,
  disabled = false,
  size = "medium",
  CustomControl = null,
  DeleteIcon = <DeleteForever />,
  minWidth = "50%",
}) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAction = async (ev) => {
    ev.preventDefault();

    if (onSaveHandler) {
      await onSaveHandler();
    }

    handleClose();
  };

  const handleDelete = async (ev) => {
    ev.preventDefault();

    if (onDeleteHandler) {
      await onDeleteHandler();
    }

    handleClose();
  };

  return (
    <>
      {CustomControl && <Box onClick={handleClickOpen}>{CustomControl}</Box>}
      {textButton && !CustomControl && (
        <Link onClick={handleClickOpen} sx={{ cursor: "pointer" }}>
          <Typography variant="body2" component="span">
            edit
          </Typography>
        </Link>
      )}
      {!textButton && !CustomControl && (
        <Box justifyContent={iconAlign}>
          <IconButton color="primary" id={id} onClick={handleClickOpen} size={size} disabled={disabled}>
            <Icon />
          </IconButton>
        </Box>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        // @ts-expect-error type mismatch
        TransitionComponent={Transition}
        scroll="paper"
        PaperProps={{ sx: { backgroundColor: background, minWidth: { xs: "95%", md: minWidth }, maxWidth: "960px" } }}
      >
        <DialogTitle>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
            {onDeleteHandler && (
              <IconButton color="inherit" onClick={handleDelete} aria-label="delete">
                {DeleteIcon}
              </IconButton>
            )}
            {actionText && (
              <Button
                id="dialog-save"
                autoFocus
                variant="outlined"
                onClick={handleAction}
                disabled={canSave}
                color="inherit"
              >
                {isBusy ? <CircularProgress size={16} /> : actionText}
              </Button>
            )}
          </Toolbar>
        </DialogTitle>
        <DialogContent>
          <Paper>
            <Box sx={{ p: { xs: 1, md: 2 } }}>
              <DialogContentText>{children}</DialogContentText>
            </Box>
          </Paper>
        </DialogContent>
      </Dialog>
    </>
  );
};
