import React from "react";
import { Path } from "../Path";
import { useGameTopics } from "@seabrookstudios/pitch2table";
import { MenuItem } from "../core/MenuItem";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const MessageBoardMenuItem = ({ gameId }) => {
  const { data: topics = [] } = useGameTopics(gameId);

  return (
    <MenuItem
      primary="Message Board"
      secondary={topics.length === 1 ? `1 thread` : `${topics.length} threads`}
      link={Path.game(gameId).topics()}
    />
  );
};
