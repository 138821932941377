import React from "react";
import { useParams } from "react-router-dom";
import { Path } from "../Path";
import { AppWithSideMenu } from "../core/AppWithSideMenu";
import { GamesBreadcrumb, makeBreadcrumbs3, MyGames } from "../core/Breadcrumbs";
import { AppLink } from "../styleguide/AppLink";
import { GameQuickLinks } from "../games/GameQuickLinks";
import { PlayTestListAndDetail } from "./ListAndDetail";
import { useGamePlayTest, useGamePlayTestMetaDataEntry } from "@seabrookstudios/pitch2table";
import Skeleton from "@mui/material/Skeleton";
import { PlayTestMetadataListAndDetail } from "./MetadataListAndDetail";
import { formatPlayTestBreadCrumbDate } from "./model";
import { PlayTesterListAndDetail } from "./PlayTesterListAndDetail";
import { PlayTestChartList } from "./PlayTestChartList";

export const routes = [
  {
    path: Path.game(":gameId").tests().pathname,
    element: (
      <AppWithSideMenu>
        <PlayTestListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs3([
        () => <MyGames key="my-games" />,
        ({ gameId }) => <GamesBreadcrumb key={gameId} gameId={gameId} />,
        ({ gameId }) => (
          <AppLink key="play-tests" to={Path.game(gameId).tests()}>
            Play Tests
          </AppLink>
        ),
      ]),
      prefer: "left",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(":gameId").test(":playTestId").pathname,
    element: (
      <AppWithSideMenu>
        <PlayTestListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs3([
        () => <MyGames key="my-games" />,
        ({ gameId }) => <GamesBreadcrumb key={gameId} gameId={gameId} />,
        ({ gameId }) => (
          <AppLink key="play-tests" to={Path.game(gameId).tests()}>
            Play Tests
          </AppLink>
        ),
        ({ gameId, playTestId }) => {
          const { data: playTest } = useGamePlayTest(gameId, playTestId);

          return (
            <AppLink key="play-test" to={Path.game(gameId).test(playTestId)}>
              {playTest ? formatPlayTestBreadCrumbDate(playTest.date) : <Skeleton />}
            </AppLink>
          );
        },
      ]),
      prefer: "right",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(":gameId").metadata().pathname,
    element: (
      <AppWithSideMenu>
        <PlayTestMetadataListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs3([
        () => <MyGames key="my-games" />,
        ({ gameId }) => <GamesBreadcrumb key={gameId} gameId={gameId} />,
        ({ gameId }) => (
          <AppLink key="play-tests" to={Path.game(gameId).tests()}>
            Play Tests
          </AppLink>
        ),
        ({ gameId }) => (
          <AppLink key="play-test-metadata" to={Path.game(gameId).metadata()}>
            Metadata
          </AppLink>
        ),
      ]),
      prefer: "left",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(":gameId").metadataEntry(":metaId").pathname,
    element: (
      <AppWithSideMenu>
        <PlayTestMetadataListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs3([
        () => <MyGames key="my-games" />,
        ({ gameId }) => <GamesBreadcrumb key={gameId} gameId={gameId} />,
        ({ gameId }) => (
          <AppLink key="play-tests" to={Path.game(gameId).tests()}>
            Play Tests
          </AppLink>
        ),
        ({ gameId }) => (
          <AppLink key="play-test-metadata" to={Path.game(gameId).metadata()}>
            Metadata
          </AppLink>
        ),
        ({ gameId, metaId }) => {
          const { data: metadata } = useGamePlayTestMetaDataEntry(gameId, metaId);

          return (
            <AppLink key="play-test" to={Path.game(gameId).metadataEntry(metaId)}>
              {metadata ? metadata.name : <Skeleton />}
            </AppLink>
          );
        },
      ]),
      prefer: "right",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(":gameId").testers().pathname,
    element: (
      <AppWithSideMenu>
        <PlayTesterListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs3([
        () => <MyGames key="my-games" />,
        ({ gameId }) => <GamesBreadcrumb key={gameId} gameId={gameId} />,
        ({ gameId }) => (
          <AppLink key="play-tests" to={Path.game(gameId).tests()}>
            Play Tests
          </AppLink>
        ),
        ({ gameId }) => (
          <AppLink key="play-testers" to={Path.game(gameId).testers()}>
            Play Testers
          </AppLink>
        ),
      ]),
      prefer: "left",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(":gameId").charts().pathname,
    element: (
      <AppWithSideMenu>
        <PlayTestChartList />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs3([
        () => <MyGames key="my-games" />,
        ({ gameId }) => <GamesBreadcrumb key={gameId} gameId={gameId} />,
        ({ gameId }) => (
          <AppLink key="play-tests" to={Path.game(gameId).tests()}>
            Play Tests
          </AppLink>
        ),
        ({ gameId }) => (
          <AppLink key="charts" to={Path.game(gameId).charts()}>
            Charts
          </AppLink>
        ),
      ]),
      prefer: "left",
      quickLinks: GameQuickLinks,
    },
  },
  {
    path: Path.game(":gameId").chart(":category").pathname,
    element: (
      <AppWithSideMenu>
        <PlayTestChartList />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs3([
        () => <MyGames key="my-games" />,
        ({ gameId }) => <GamesBreadcrumb key={gameId} gameId={gameId} />,
        ({ gameId }) => (
          <AppLink key="play-tests" to={Path.game(gameId).tests()}>
            Play Tests
          </AppLink>
        ),
        ({ gameId }) => (
          <AppLink key="charts" to={Path.game(gameId).charts()}>
            Charts
          </AppLink>
        ),
        ({ gameId, category }) => {
          if (category === "score-composition") {
            return (
              <AppLink key="charts" to={Path.game(gameId).chart(":category")}>
                Score Composition
              </AppLink>
            );
          }

          return (
            <AppLink key="charts" to={Path.game(gameId).chart(":category")}>
              Unknown Chart
            </AppLink>
          );
        },
      ]),
      prefer: "right",
      quickLinks: GameQuickLinks,
    },
  },
];

/**
 * @returns {PlayTestCharts|undefined}
 */
export const useCategoryFromRoute = () => {
  return /** @type {PlayTestCharts} */ (useParams().category) || undefined;
};
