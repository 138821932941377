import React, { useState } from "react";
import { FullScreenDialog } from "./FullScreenDialog";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { SmartTextField } from "./SmartTextField";

/**
 * @param {object} props
 * @param {string} props.helperText
 * @param {string} props.title
 * @param {string|number|undefined} props.initialValue
 * @param {boolean} [props.multiline]
 * @param {boolean} props.isBusy
 * @param {import("react").ReactNode} props.children
 * @param {(text: (string|number|undefined)) => Promise<void>} props.onSave
 */
export const EditTextFieldDialog = ({ helperText, onSave, title, isBusy, children, initialValue, multiline }) => {
  const [updateText, setUpdateText] = useState(initialValue);

  const onSaveHandler = () => {
    onSave(updateText);
  };

  return (
    <FullScreenDialog
      title={`Edit ${title.toLowerCase()}`}
      actionText="SAVE"
      onSaveHandler={onSaveHandler}
      isBusy={isBusy}
      CustomControl={children}
    >
      <Card elevation={2}>
        <Box p={1}>
          <SmartTextField
            initialValue={initialValue}
            onChange={setUpdateText}
            onEnter={setUpdateText}
            onEnterDisabled={false}
            autoFocus
            label={title}
            helperText={helperText}
            multiline={multiline}
          />
        </Box>
      </Card>
    </FullScreenDialog>
  );
};
