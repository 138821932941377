import React from "react";
import { useNavigate } from "react-router-dom";
import { Path } from "../Path";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import { showContracts } from "./contracts/model";
import Article from "@mui/icons-material/Article";
import Money from "@mui/icons-material/Money";
import BusinessIcon from "@mui/icons-material/Business";
import Topic from "@mui/icons-material/Topic";
import Tooltip from "@mui/material/Tooltip";
import Games from "@mui/icons-material/Games";
import Group from "@mui/icons-material/Group";
import Rule from "@mui/icons-material/Rule";
import Sell from "@mui/icons-material/Sell";
import LocalPlay from "@mui/icons-material/LocalPlay";
import { useGame } from "@seabrookstudios/pitch2table";
import { useGameIdFromRoute } from "./WithGameIdFromRoute";

/**
 * @param {object} props
 * @param {string} props.id
 * @param {import("@mui/material/OverridableComponent").OverridableComponent<import("@mui/material").SvgIconTypeMap<{}, "svg">> & { muiName: string; }} props.Icon
 * @param {string} props.title
 * @param {boolean} [props.disabled]
 * @param {import("react-router-dom").To} props.to
 */
function QuickLink({ id, Icon, title, to, disabled = false }) {
  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(to);
  };

  return (
    <Tooltip title={title}>
      <span id={id}>
        <IconButton onClick={onClickHandler} disabled={disabled}>
          <Icon fontSize="small" />
        </IconButton>
      </span>
    </Tooltip>
  );
}

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
const GamesHome = ({ gameId }) => {
  return <QuickLink id="game-overview" title="Game Overview" Icon={Games} to={Path.game(gameId).link()} />;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
const PublisherInterest = ({ gameId }) => {
  return (
    <QuickLink
      id="publisher-interest"
      title="Publisher Interest"
      Icon={BusinessIcon}
      to={Path.game(gameId).publishers()}
    />
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
const Royalties = ({ gameId }) => {
  const { data: game } = useGame(gameId);

  if (!game) {
    return (
      <QuickLink
        id="game-royalties"
        title="Royalties are available once the game has entered Pitching Now, Signed or Published status"
        Icon={Money}
        to={Path.game(gameId).royalties()}
        disabled
      />
    );
  }

  if (!showContracts(game)) {
    return (
      <QuickLink
        id="game-royalties"
        title="Royalties are available once the game has entered Pitching Now, Signed or Published status"
        Icon={Money}
        to={Path.game(gameId).royalties()}
        disabled
      />
    );
  }

  return <QuickLink id="game-royalties" title="Royalties" Icon={Money} to={Path.game(gameId).royalties()} />;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
const MessageBoard = ({ gameId }) => {
  return <QuickLink id="message-board" title="Message Board" Icon={Topic} to={Path.game(gameId).topics()} />;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
const Rules = ({ gameId }) => {
  return <QuickLink id="game-rules" title="Rules" Icon={Rule} to={Path.game(gameId).rules()} />;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
const SellSheets = ({ gameId }) => {
  return <QuickLink id="game-sell-sheets" title="Sell Sheets" Icon={Sell} to={Path.game(gameId).sellsheets()} />;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
const Designers = ({ gameId }) => {
  return <QuickLink id="game-designers" title="Designers" Icon={Group} to={Path.game(gameId).designers()} />;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
const PlayTests = ({ gameId }) => {
  return <QuickLink id="play-tests" title="Play Tests" Icon={LocalPlay} to={Path.game(gameId).tests()} />;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
const Contracts = ({ gameId }) => {
  const { data: game } = useGame(gameId);

  if (!game) {
    return (
      <QuickLink
        id="game-contracts"
        title="Contracts are available once the game has entered Pitching Now, Signed or Published status"
        Icon={Article}
        to={Path.game(gameId).contracts()}
        disabled
      />
    );
  }

  if (!showContracts(game)) {
    return (
      <QuickLink
        id="game-contracts"
        title="Contracts are available once the game has entered Pitching Now, Signed or Published status"
        Icon={Article}
        to={Path.game(gameId).contracts()}
        disabled
      />
    );
  }

  return <QuickLink id="game-contracts" title="Contracts" Icon={Article} to={Path.game(gameId).contracts()} />;
};

export const GameQuickLinks = () => {
  const gameId = useGameIdFromRoute();

  return (
    <Stack direction="row" p={1} sx={{ flexWrap: "wrap", justifyContent: { xs: "center", md: "flex-end" } }}>
      <GamesHome gameId={gameId} />
      <Designers gameId={gameId} />
      <MessageBoard gameId={gameId} />
      <PlayTests gameId={gameId} />
      <Rules gameId={gameId} />
      <SellSheets gameId={gameId} />
      <PublisherInterest gameId={gameId} />
      <Contracts gameId={gameId} />
      <Royalties gameId={gameId} />
    </Stack>
  );
};
