import { useGamePlayTests } from "@seabrookstudios/pitch2table";
import { alphabetically } from "@seabrookstudios/types";
import { useQuery } from "@tanstack/react-query";
import { DateTime } from "luxon";

/**
 * @typedef {object} PlayTesterSummary
 * @property {string} id
 * @property {string} name
 * @property {number} count
 */

/**
 * @param {number} date
 */
export const formatPlayTestBreadCrumbDate = (date = DateTime.now().toMillis()) => {
  return DateTime.fromMillis(date).toFormat("yyyy-MM-dd");
};

/**
 * @returns {import("@seabrookstudios/pitch2table-core").PlayTestPlayer}
 */
export const makePlayer = (name) => {
  return {
    id: window.crypto.randomUUID(),
    name,
    scores: [],
    won: false,
  };
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestPlayer} player
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestMetadataComponentId} metaId
 * @returns {number|undefined}
 */
export const getValueForMetaForPlayer = (player, metaId) => {
  const score = player.scores.find((s) => s.id === metaId);
  if (!score) {
    return undefined;
  }

  return score.value;
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestOutcomes[]} nonPlayerOutcomes
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestMetadataComponentId} metaId
 * @returns {number|undefined}
 */
export const getNonPlayerValue = (nonPlayerOutcomes, metaId) => {
  const score = nonPlayerOutcomes.find((s) => s.id === metaId);
  if (!score) {
    return undefined;
  }

  return score.value;
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestPlayer} player
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestMetadataComponent[]} meta
 * @returns {number|undefined}
 */
export const calculateScoreForPlayer = (player, meta) => {
  const mIds = meta.filter((m) => m.type === "PART_OF_SCORE").map((m) => m.id);

  const score = player.scores.filter((s) => mIds.includes(s.id)).reduce((t, v) => t + v.value, 0);

  return score;
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestPlayer[]} outcomes
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestPlayer} player
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestMetadataComponentId} metaId
 * @param {number} value
 * @returns {import("@seabrookstudios/pitch2table-core").PlayTestPlayer[]}
 */
export const updateValueForMetaForPlayer = (outcomes, player, metaId, value) => {
  const index = outcomes.findIndex((pl) => pl.id === player.id);
  const b4 = outcomes.filter((_, i) => i < index);
  const a4 = outcomes.filter((_, i) => i > index);

  const otherItems = player.scores.filter((s) => s.id !== metaId);

  const updatedPlayer = {
    ...player,
    scores: [...otherItems, { id: metaId, value }],
  };

  return [...b4, updatedPlayer, ...a4];
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").PlayTest[]} playTests
 * @returns {number}
 */
export const countUniquePlayTesters = (playTests) => {
  return playTests
    .map((pt) => pt.outcomes)
    .reduce((acc, v) => [...acc, ...v], [])
    .map((o) => o.name)
    .reduce((acc, n) => (acc.includes(n) ? acc : [...acc, n]), /** @type {string[]} */ ([])).length;
};

/**
 * @param {number} c
 */
export const formatPlayerCount = (c) => {
  if (c === 0) {
    return "No Results";
  }
  if (c === 1) {
    return "SOLO";
  }

  return `${c}P`;
};

/**
 * @param {number} c
 */
export const formatPlays = (c) => {
  if (c === 1) {
    return "1 play";
  }

  return `${c} plays`;
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").PlayTest} pt
 */
export const formatPlayers = (pt) => {
  if (pt.outcomes.length === 0) {
    return "No results";
  }

  return pt.outcomes.map((o) => o.name).join(", ");
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestPlayer[]} outcomes
 */
export const formatPlayers2 = (outcomes) => {
  if (outcomes.length === 0) {
    return "No results";
  }

  return outcomes.map((o) => o.name).join(", ");
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").PlayTest} pt
 */
export const formatWinner = (pt) => {
  const winners = pt.outcomes.filter((o) => o.won);

  if (winners.length === 0) {
    return "Winner TBD";
  }

  if (winners.length === 1) {
    return `Winner: ${winners.map((o) => o.name).join(", ")}`;
  }

  return `Winners: ${winners.map((o) => o.name).join(", ")}`;
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").PlayTest} pt
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestMetadataComponent[]} metadata
 */
export const formatScores = (pt, metadata = []) => {
  if (!pt.ignore) {
    return "";
  }

  const score = metadata.find((m) => m.type === "SCORE");
  if (!score) {
    return "";
  }

  const scores = pt.outcomes
    .map((o) => o.scores.filter((s) => s.id === score.id))
    .reduce((arr, v) => [...arr, ...v], [])
    .map((s) => s.value)
    .toSorted((a, b) => b - a)
    .join(" - ");

  return scores;
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestPlayer[]} outcomes
 */
export const formatWinner2 = (outcomes) => {
  const winners = outcomes.filter((o) => o.won);

  if (winners.length === 0) {
    return "Winner TBD";
  }

  if (winners.length === 1) {
    return `Winner: ${winners.map((o) => o.name).join(", ")}`;
  }

  return `Winners: ${winners.map((o) => o.name).join(", ")}`;
};

/**
 * @param {number} testerCount
 * @param {number} playTestCount
 */
export const formatPlayTesterSummary = (testerCount, playTestCount) => {
  const part1 = testerCount === 1 ? "1 unique play tester" : `${testerCount} unique play testers`;
  const part2 = playTestCount === 1 ? "1 play" : `${playTestCount} plays`;

  return `${part1} over ${part2}`;
};

/**
 * @param {import("@seabrookstudios/types").GameId} gameId
 */
export const usePlayTesters = (gameId) => {
  const { data: playTests = [] } = useGamePlayTests(gameId);

  return useQuery({
    queryKey: [`play-testers-${gameId}`],
    queryFn: () => {
      /**
       * @type {PlayTesterSummary[]}
       */
      const summary = playTests
        .map((pt) => pt.outcomes)
        .reduce((acc, v) => [...acc, ...v], [])
        .map((o) => ({
          id: o.id,
          name: o.name,
          count: 0,
        }))
        .reduce((acc, n) => {
          const notPlayer = acc.filter((p) => p.name !== n.name);
          const player = acc.find((p) => p.name === n.name);

          if (!player) {
            return [...notPlayer, { ...n, count: 1 }];
          }

          return [...notPlayer, { ...player, count: player.count + 1 }];
        }, /** @type {PlayTesterSummary[]} */ ([]));

      summary.sort(alphabetically("name"));

      return summary;
    },
    staleTime: 1000,
  });
};

/**
 * @param {import("@seabrookstudios/types").GameId} gameId
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestMetadataComponentId} metaId
 */
export const useScoreResults = (gameId, metaId) => {
  const { data: playTests = [] } = useGamePlayTests(gameId);

  if (playTests.length === 0) {
    return [];
  }

  const scoresByPlayTest = playTests
    .filter((pt) => pt.ignore)
    .toSorted((a, b) => a.date - b.date)
    .map((pt) => {
      const scores = pt.outcomes.map((o) => getValueForMetaForPlayer(o, metaId) || null);

      scores.sort((a, b) => {
        if (!a) {
          return -1;
        }
        if (!b) {
          return 1;
        }

        return b - a;
      });

      return scores;
    });

  const players = scoresByPlayTest.map((s) => s.length);
  const max = Math.max(...players);

  return Array(max)
    .fill(0)
    .map((_, i) => i)
    .map((i) => scoresByPlayTest.map((scores) => scores[i]));
};

/**
 * @param {import("@seabrookstudios/types").GameId} gameId
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestMetadataComponentId} metaId
 */
export const useMaxPlayers = (gameId, metaId) => {
  const { data: playTests = [] } = useGamePlayTests(gameId);

  if (playTests.length === 0) {
    return 0;
  }

  const scoresByPlayTest = playTests
    .filter((pt) => pt.ignore)
    .map((pt) => {
      const scores = pt.outcomes.map((o) => getValueForMetaForPlayer(o, metaId) || null);
      return scores;
    });

  const players = scoresByPlayTest.map((s) => s.length);
  const max = Math.max(...players);

  return max;
};

/**
 * @param {import("@seabrookstudios/types").GameId} gameId
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestMetadataComponentId} metaId
 */
export const useScoreResults2 = (gameId, metaId) => {
  const { data: playTests = [] } = useGamePlayTests(gameId);

  if (playTests.length === 0) {
    return [];
  }

  const scoresByPlayTest = playTests
    .filter((pt) => pt.ignore)
    .toSorted((a, b) => a.date - b.date)
    .filter((pt) => {
      const scores = pt.outcomes.map((o) => getValueForMetaForPlayer(o, metaId) || null);
      return scores.filter((v) => v !== undefined && v !== null).length > 0;
    })
    .map((pt, no) => {
      const scores = pt.outcomes.map((o) => getValueForMetaForPlayer(o, metaId) || null);

      scores.sort((a, b) => {
        if (!a) {
          return -1;
        }
        if (!b) {
          return 1;
        }

        return b - a;
      });

      return scores.reduce((obj, v, i) => ({ ...obj, [i + 1]: v }), { date: pt.date, no, id: pt.id });
    });

  return scoresByPlayTest;
};

/**
 * @param {import("@seabrookstudios/types").GameId} gameId
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestMetadataComponentId[]} metaIds
 * @param {boolean} winnersOnly
 */
export const useScoreParts = (gameId, metaIds, winnersOnly) => {
  const { data: playTests = [] } = useGamePlayTests(gameId);

  if (playTests.length === 0 || metaIds.length === 0) {
    return [];
  }

  const scoresByPlayTest = playTests
    .filter((pt) => pt.ignore)
    .map((pt) => {
      const xz = pt.outcomes
        .filter((o) => (winnersOnly ? o.won : true))
        .filter((o) => {
          return o.scores.filter((s) => metaIds.includes(s.id)).filter((v) => v !== undefined && v !== null).length > 0;
        })
        .map((o) => {
          return o.scores.filter((s) => metaIds.includes(s.id)).reduce((obj, s) => ({ ...obj, [s.id]: s.value }), {});
        });

      return xz;
    })
    .reduce((acc, v) => [...acc, ...v], []);

  return scoresByPlayTest;
};

/**
 * @param {import("@seabrookstudios/types").GameId} gameId
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestMetadataComponentId} metaId
 */
export const useGameLevelResults = (gameId, metaId) => {
  const { data: playTests = [] } = useGamePlayTests(gameId);

  if (playTests.length === 0) {
    return [];
  }

  const values = playTests
    .filter((pt) => pt.ignore)
    .filter((pt) => getNonPlayerValue(pt.nonPlayerOutcomes, metaId) !== undefined)
    .map((pt) => {
      return getNonPlayerValue(pt.nonPlayerOutcomes, metaId);
    });

  return values;
};

export const formatPlace = (p) => {
  if (p === 1) {
    return "1st";
  }
  if (p === 2) {
    return "2nd";
  }
  if (p === 3) {
    return "3rd";
  }

  return `${p}th`;
};
