import { DateTime } from "luxon";

/**
 * @param {number} days
 */
const daysAgo = (days) => DateTime.now().minus({ days }).startOf("day").toMillis();

/**
 * @param {number} count
 * @returns {Filter<import("@seabrookstudios/pitch2table-core").PlayTest>}
 */
const makePlayerCount = (count) => ({
  filter: (pt) => pt.outcomes.length === count,
  label: `${count}P`,
  count: 0,
  weight: 0 - count * 10,
});

/**
 * @type {Filter<import("@seabrookstudios/pitch2table-core").PlayTest>[]}
 */
export const PlayTestFilters = [
  {
    filter: (pt) => pt.date > daysAgo(7) && pt.ignore,
    label: "RECENT",
    count: 0,
    weight: 75,
  },
  {
    filter: (pt) => pt.complete && pt.ignore,
    label: "COMPLETED",
    count: 0,
    weight: 50,
  },
  {
    filter: (pt) => pt.outcomes.length === 1 && pt.ignore,
    label: "SOLO",
    count: 0,
    weight: 0,
  },
  ...Array(99)
    .fill(0)
    .map((_, i) => i + 1)
    .map((c) => makePlayerCount(c)),
  {
    filter: () => true,
    label: "ALL",
    count: 0,
    weight: -1000,
  },
  {
    filter: (pt) => !pt.ignore,
    label: "IGNORED",
    count: 0,
    weight: -2000,
  },
];
