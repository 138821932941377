import React, { useState } from "react";
import { ScoreCompositionChart } from "./Chart";
import { SinglePageView } from "../core/ListView";
import Alert from "@mui/material/Alert";
import { AlwaysOnAppLink } from "../styleguide/AppLink";
import { Path } from "../Path";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import HorizontalSplit from "@mui/icons-material/HorizontalSplit";
import StackedBarChart from "@mui/icons-material/StackedBarChart";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import AllInclusive from "@mui/icons-material/AllInclusive";
import Stack from "@mui/material/Stack";
import { useGamePlayTestMetaData } from "@seabrookstudios/pitch2table";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const ScoreComposition = ({ gameId }) => {
  const { data: metaData = [] } = useGamePlayTestMetaData(gameId);

  const [proportional, setProportional] = useState(false);
  const [winnersOnly, setWinnersOnly] = useState(false);

  const toggleWinnersOnly = () => {
    setWinnersOnly((x) => !x);
  };

  const toggleProportional = () => {
    setProportional((x) => !x);
  };

  const ChartActions = () => {
    return (
      <Stack direction="row" pr={1}>
        <Tooltip title={"Toggle between showing all players or only winners"}>
          <IconButton onClick={toggleWinnersOnly}>{winnersOnly ? <AllInclusive /> : <EmojiEventsIcon />}</IconButton>
        </Tooltip>
        <Tooltip title="toggle proportional view">
          <IconButton onClick={toggleProportional}>
            {proportional ? <StackedBarChart /> : <HorizontalSplit />}
          </IconButton>
        </Tooltip>
      </Stack>
    );
  };

  return (
    <SinglePageView title={`Score Composition (${winnersOnly ? "Winners" : "All players"})`} Action={ChartActions}>
      {metaData.filter((m) => m.type === "PART_OF_SCORE").length === 0 ? (
        <Box p={1}>
          <Alert severity="warning">
            To use this chart you need to track the parts of your score and update your play tests. Define{" "}
            <AlwaysOnAppLink to={Path.game(gameId).metadata()}>tracking data</AlwaysOnAppLink> so you can see how each
            part contributed to a players score.
          </Alert>
        </Box>
      ) : (
        <Box p={1}>
          <Alert severity="info">
            This chart stacks the part of score{" "}
            <AlwaysOnAppLink to={Path.game(gameId).metadata()}>tracking data</AlwaysOnAppLink> so you can see how each
            part contributed to a players score.
          </Alert>
        </Box>
      )}
      <ScoreCompositionChart gameId={gameId} proportional={proportional} winnersOnly={winnersOnly} />
    </SinglePageView>
  );
};
