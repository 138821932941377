import React from "react";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";
import {
  PlayTestMetadataComponentTypeTypes,
  PlayTestMetadataComponentTypeLabels,
  PlayTestMetadataComponentTypeDescriptions,
} from "@seabrookstudios/pitch2table-core";
import CircularProgress from "@mui/material/CircularProgress";

const Label = "Play Test Tracking Variable Type";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestMetadataComponentType} props.value
 * @param {(value: import("@seabrookstudios/pitch2table-core").PlayTestMetadataComponentType) => void} props.onChange
 * @param {boolean} [props.isPending]
 * @param {string} props.helperText
 * @param {boolean} [props.disabled]
 */
export const MetadataTypeSelect = ({ value, onChange, helperText, disabled = false, isPending = false }) => {
  /**
   * @param {*} ev
   */
  const onChangeHandler = (ev) => {
    onChange(ev.target.value);
  };

  return (
    <FormControl sx={{ width: "100%" }} margin="normal" disabled={disabled}>
      <InputLabel id="play-test-metadata-type-label">{Label}</InputLabel>
      <Select
        labelId="play-test-metadata-type-label"
        id="play-test-metadata-type"
        value={value}
        onChange={onChangeHandler}
        placeholder={Label}
        label={Label}
        input={<OutlinedInput label={Label} />}
      >
        {isPending ? (
          <CircularProgress />
        ) : (
          PlayTestMetadataComponentTypeTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {PlayTestMetadataComponentTypeLabels[type]} - {PlayTestMetadataComponentTypeDescriptions[type]}
            </MenuItem>
          ))
        )}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
