import React from "react";
import ReactQuill from "react-quill";
import "./editor.css";
import Typography from "@mui/material/Typography";
import { domToReact } from "html-react-parser";
import Link from "@mui/material/Link";
import OpenInNew from "@mui/icons-material/OpenInNew";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

/**
 * to support images we would need to:
 *  a) capture the image, upload it and then insert a reference
 *  b) only allow inserting of previously uploaded images
 */

export const Editor = ({ value, onChange }) => {
  return (
    <div data-text-editor="name">
      <ReactQuill
        theme="snow"
        value={value}
        onChange={(text, ...x) => {
          console.log(text);
          console.log(text.replace('<li class-"ql-indent-2">', 'level="2"'));
          onChange(text, ...x);
        }}
        bounds={`[data-text-editor="name"]`}
        modules={{
          toolbar: [
            [{ header: 1 }, { header: 2 }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }],
            // [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            // [{ indent: "-1" }, { indent: "+1" }],
            ["link"],
            ["clean"],
          ],
          clipboard: {
            matchVisual: false,
          },
        }}
        formats={["header", "bold", "italic", "strike", "blockquote", "underline", "list", "bullet", "link", "indent"]}
      />
    </div>
  );
};

/**
 * @type {import("html-react-parser").HTMLReactParserOptions}
 */
export const options = {
  replace: ({ attribs, name, children }) => {
    if (!attribs) {
      return;
    }

    if (name === "h1") {
      return (
        <Typography variant="h1" {...attribs}>
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === "h2") {
      return (
        <Typography variant="h2" {...attribs}>
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === "h3") {
      return (
        <Typography variant="h3" {...attribs}>
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === "h4") {
      return (
        <Typography variant="h4" {...attribs}>
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === "h5") {
      return (
        <Typography variant="h5" {...attribs}>
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === "h6") {
      return (
        <Typography variant="h6" {...attribs}>
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === "p") {
      return (
        <Typography whiteSpace="pre-wrap" {...attribs}>
          {domToReact(children, options)}
        </Typography>
      );
    }
    if (name === "a") {
      return (
        <Link {...attribs} rel="noopener" target="_blank" underline="always" display="flex" alignItems="center">
          {domToReact(children, options)}
          &nbsp;
          <OpenInNew fontSize="small" />
        </Link>
      );
    }
    if (name === "strong") {
      return <strong {...attribs}>{domToReact(children, options)}</strong>;
    }
    if (name === "em") {
      return <em {...attribs}>{domToReact(children, options)}</em>;
    }
    if (name === "u") {
      return <u {...attribs}>{domToReact(children, options)}</u>;
    }
    if (name === "s") {
      return <s {...attribs}>{domToReact(children, options)}</s>;
    }
    if (name === "blockquote") {
      return <blockquote {...attribs}>{domToReact(children, options)}</blockquote>;
    }
    if (name === "ol") {
      return (
        <List sx={{ listStyle: "decimal", pl: 4 }} {...attribs}>
          {domToReact(children, options)}
        </List>
      );
    }
    if (name === "ul") {
      return (
        <List sx={{ listStyleType: "disc", pl: 4 }} {...attribs}>
          {domToReact(children, options)}
        </List>
      );
    }
    if (name === "li") {
      return (
        <ListItem sx={{ display: "list-item" }} {...attribs}>
          {domToReact(children, options)}
        </ListItem>
      );
    }
    if (name === "br") {
      return <br {...attribs} />;
    }

    return <span>{domToReact(children, options)}</span>;
  },
};
