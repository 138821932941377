import React from "react";
import { useGameIdFromRoute } from "./WithGameIdFromRoute";
import { NoPublisherInterest, PublisherListEntry2 } from "./PublisherInterestList";
import { PublisherInterestInGame } from "./PublisherInterestInGame";
import { useGamePublishers } from "@seabrookstudios/pitch2table";
import { PublisherListFilters } from "./publisher-interest/filters";
import { idExtractor, useMakeListAndDetail, useMakeListBuilder } from "../core/useMakeLayout";
import { AddPublisherInterest } from "./publisher-interest/AddPublisherInterest";

export const PublisherInterestListAndDetail = () => {
  const gameId = useGameIdFromRoute();

  const Action = () => <AddPublisherInterest gameId={gameId} />;

  const useMakeList = useMakeListBuilder(
    PublisherListEntry2,
    idExtractor,
    "Publisher Interest",
    NoPublisherInterest,
    Action
  );

  const useWrappedQuery = () => useGamePublishers(gameId);

  const ListAndDetail = useMakeListAndDetail(
    useWrappedQuery,
    PublisherListFilters,
    "publisher-interest",
    useMakeList,
    PublisherInterestInGame
  );

  return <ListAndDetail />;
};
