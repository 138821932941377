import React from "react";
import { useGamePlayTestMetaData } from "@seabrookstudios/pitch2table";
import { NoPlayTestMetadata } from "./Empty";
import { useGameIdFromRoute } from "../games/WithGameIdFromRoute";
import { idExtractor, useMakeListAndDetail, useMakeListBuilder } from "../core/useMakeLayout";
import { AddPlayTestMetadataToGame } from "./Add";
import { Path } from "../Path";
import { PlayTestMetadataListEntry } from "./ListEntry";
import { PlayTestMetadataFromRoute } from "./FromRoute";

export const PlayTestMetadataListAndDetail = () => {
  const gameId = useGameIdFromRoute();

  const Action = () => <AddPlayTestMetadataToGame id="add-play-test-metadata" gameId={gameId} />;

  const useMakeList = useMakeListBuilder(
    PlayTestMetadataListEntry,
    idExtractor,
    "Play Test Metadata",
    NoPlayTestMetadata,
    Action,
    undefined,
    Path.game(gameId).tests()
  );

  const useWrappedQuery = () => useGamePlayTestMetaData(gameId);

  const ListAndDetail = useMakeListAndDetail(
    useWrappedQuery,
    [],
    "play-test-metadata",
    useMakeList,
    PlayTestMetadataFromRoute
  );

  return <ListAndDetail />;
};
