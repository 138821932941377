import React from "react";
import { FullScreenDialog } from "../core/FullScreenDialog";
import { PremiumUploadRulesComponent, UploadRulesComponent } from "./UploadRulesComponent";
import { useMyEntitlements } from "@seabrookstudios/pitch2table";
import Typography from "@mui/material/Typography";

export default function AddRulesFullScreenDialog({ gameId }) {
  const { data: entitlements } = useMyEntitlements();

  const hasLargeFileUploads = entitlements && entitlements.hasLargeFileUploads;

  return (
    <FullScreenDialog title="Add Rules" canSave={true}>
      {hasLargeFileUploads ? <PremiumUploadRulesComponent gameId={gameId} /> : <UploadRulesComponent gameId={gameId} />}
      <Typography variant="body2" textAlign="right" color="text.secondary">
        Maximum file size: {hasLargeFileUploads ? "500MB" : "5MB"}
      </Typography>
    </FullScreenDialog>
  );
}
