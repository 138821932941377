/**
 * @param {import("@seabrookstudios/pitch2table-core").PublicContact|import("@seabrookstudios/pitch2table-core").AdminOnlyPublicContact} contact
 */
export const formatFullName = (contact) => {
  if (!contact.lastName) {
    return `${contact.firstName}`.trim();
  }

  return `${contact.firstName} ${contact.lastName}`.trim();
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").PublicContact|import("@seabrookstudios/pitch2table-core").AdminOnlyPublicContact|undefined} contact
 */
export const canSendEmails = (contact) => {
  if (!contact) {
    return false;
  }
  if (!contact.firstName) {
    return false;
  }

  return true;
};
