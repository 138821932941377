import { enqueueSnackbar } from "notistack";

export const onSaveSuccess = () => {
  enqueueSnackbar("Updated!", { variant: "success", autoHideDuration: 3000 });
};

export const makeOnSuccess = (message) => () => {
  enqueueSnackbar(message, { variant: "success", autoHideDuration: 3000 });
};

export const onSaveFailed = () => {
  enqueueSnackbar("Update Failed! Please refresh and try again.", { variant: "error", autoHideDuration: 3000 });
};
