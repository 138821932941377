import React from "react";
import { NoPlayTests } from "./Empty";
import { useGameIdFromRoute } from "../games/WithGameIdFromRoute";
import { idExtractor, useMakeListAndDetail, useMakeListBuilder } from "../core/useMakeLayout";
import { Path } from "../Path";
import { PlayTesterCopyAll, PlayTesterEntry } from "./ListEntry";
import { usePlayTesters } from "./model";
import Box from "@mui/material/Box";

export const PlayTesterListAndDetail = () => {
  const gameId = useGameIdFromRoute();

  const useMakeList = useMakeListBuilder(
    PlayTesterEntry,
    idExtractor,
    "Play Testers",
    NoPlayTests,
    undefined,
    PlayTesterCopyAll,
    Path.game(gameId).tests()
  );

  const useWrappedQuery = () => usePlayTesters(gameId);

  const ListAndDetail = useMakeListAndDetail(useWrappedQuery, [], "play-testers", useMakeList, () => <Box />);

  return <ListAndDetail />;
};
