import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { SmartTextField } from "../../core/SmartTextField";
import { FullScreenDialog } from "../../core/FullScreenDialog";
import Alert from "@mui/material/Alert";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Heading } from "../../core/Heading";
import { enqueueSnackbar } from "notistack";
import { useFindByEmail, useInviteUser } from "@seabrookstudios/pitch2table";
import { formatContactLocation } from "@seabrookstudios/pitch2table-core";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {string} props.email
 */
const SearchResults = ({ gameId, email }) => {
  const { data: contact, isFetching } = useFindByEmail(email);

  const onAddUserSuccess = () => {
    enqueueSnackbar("Invite Sent", { variant: "success" });
  };

  /**
   * @param {import("axios").AxiosError} error
   */
  const onAddUserError = ({ response }) => {
    if (response && response.status === 403) {
      enqueueSnackbar("ACCESS DENIED.", { variant: "error" });
      return;
    }
    if (response && response.status === 404) {
      enqueueSnackbar("Not Found. Please refresh and try again", { variant: "error" });
      return;
    }

    enqueueSnackbar("An error occured.", { variant: "error" });
  };

  const inviteUser = useInviteUser(gameId, onAddUserSuccess, onAddUserError);

  if (!email) {
    return <Typography></Typography>;
  }

  const addUserHandler = () => {
    if (!contact) {
      enqueueSnackbar("An error occured.", { variant: "error" });
      return;
    }

    inviteUser.mutate({ userId: contact.id });
  };

  if (isFetching) {
    return (
      <Box p={1}>
        <Card>
          <Box p={3} display="flex" justifyContent="center" alignItems="center">
            <CircularProgress />
          </Box>
        </Card>
      </Box>
    );
  }

  if (!contact) {
    return (
      <Box p={1}>
        <Card>
          <Box p={3}>
            <Typography>No results found for "{email}"</Typography>
          </Box>
        </Card>
      </Box>
    );
  }

  const contactTime = DateTime.now().setZone(contact.location.timezone);
  const text = "Invite Designer";

  return (
    <Box p={1}>
      <Card elevation={2}>
        <Box p={3}>
          <Stack direction="row" display="flex">
            <Stack flex={1} justifyContent="center" alignItems="center">
              <Avatar src={contact.pictureUrl} style={{ width: 150, height: 150 }} />
            </Stack>
            <Stack flex={2}>
              <Heading variant="h5">
                {contact.firstName} {contact.lastName}
              </Heading>
              <Typography pb={1}>{contact.pronouns}</Typography>
              <Divider />
              <Box>
                <Stack direction="column" display="flex">
                  <Stack direction="row" pt={1}>
                    <Stack flex={1} justifyContent="center">
                      <Typography variant="body2" textTransform="uppercase" color="text.secondary">
                        Preferred Name
                      </Typography>
                    </Stack>
                    <Stack flex={1} justifyContent="center">
                      <Typography>{contact.preferredName || "Unknown"}</Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" pt={1}>
                    <Stack flex={1} justifyContent="center">
                      <Typography variant="body2" textTransform="uppercase" color="text.secondary">
                        Email
                      </Typography>
                    </Stack>
                    <Stack flex={1} justifyContent="center">
                      <Typography>{email}</Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" pt={1}>
                    <Stack flex={1} justifyContent="center">
                      <Typography variant="body2" textTransform="uppercase" color="text.secondary">
                        Location
                      </Typography>
                    </Stack>
                    <Stack flex={1} justifyContent="center">
                      <Typography>{formatContactLocation(contact.location) || "Unknown"}</Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" pt={1}>
                    <Stack flex={1} justifyContent="center">
                      <Typography variant="body2" textTransform="uppercase" color="text.secondary">
                        Time Zone
                      </Typography>
                    </Stack>
                    <Stack flex={1} justifyContent="center">
                      <Typography>{contact.location.timezone || "Unknown"}</Typography>
                    </Stack>
                  </Stack>
                  <Stack direction="row" pt={1}>
                    <Stack flex={1} justifyContent="center">
                      <Typography variant="body2" textTransform="uppercase" color="text.secondary">
                        Local Time
                      </Typography>
                    </Stack>
                    <Stack flex={1} justifyContent="center">
                      <Typography>
                        {contact.location.timezone ? contactTime.toFormat("DDD @ TTT") : "Unknown"}
                      </Typography>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </Box>
        <CardContent>
          <Alert severity="warning">
            Your co-designer will have the same access as you to this game. Make sure you are inviting the correct
            person.
          </Alert>
        </CardContent>
        <CardActions>
          <Button onClick={addUserHandler}>{inviteUser.isPending ? <CircularProgress size={16} /> : text}</Button>
        </CardActions>
      </Card>
    </Box>
  );
};

/**
 * @param {object} props
 * @param {boolean} props.disabled
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const InviteDesignerToGame = ({ disabled, gameId }) => {
  const [text, setText] = useState("");
  /**
   * @type {[(string|null),import("react").Dispatch<import("react").SetStateAction<(string|null)>>]}
   */
  const [email, setEmail] = useState(null);
  const [searchDisabled, setSearchDisabled] = useState(true);

  const searchForUser = async () => {
    setEmail(text);
  };

  useEffect(() => {
    setSearchDisabled(text.length === 0 || !text.includes("@"));
  }, [text]);

  return (
    <FullScreenDialog disabled={disabled} title="Invite Co-designer">
      <Box p={1}>
        <Card elevation={2}>
          <Box p={1}>
            <Stack direction="row">
              <SmartTextField
                required
                label="Email Address"
                helperText="Look up the email address of an existing user. Exact match only."
                onChange={setText}
                onEnter={searchForUser}
                onEnterDisabled={searchDisabled}
              />
              <Box display="flex" justifyContent="center" alignItems="center" p={1}>
                <Button variant="contained" onClick={searchForUser} disabled={searchDisabled}>
                  SEARCH
                </Button>
              </Box>
            </Stack>
          </Box>
        </Card>
      </Box>
      <SearchResults gameId={gameId} email={email || ""} />
    </FullScreenDialog>
  );
};
