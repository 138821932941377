import React from "react";
import { ListDetail } from "../web/ListDetail";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { Path } from "../Path";
import { useNavigate } from "react-router-dom";
import { ListView } from "../core/ListView";
import Badge from "@mui/material/Badge";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import StackedBarChart from "@mui/icons-material/StackedBarChart";
import { useCategoryFromRoute } from "./routes";
import Alert from "@mui/material/Alert";
import { AlwaysOnAppLink } from "../styleguide/AppLink";
import { useGameIdFromRoute } from "../games/WithGameIdFromRoute";
import Box from "@mui/material/Box";
import { ScoreComposition } from "./ScoreComposition";

/**
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.category
 * @param {string} props.helperText
 * @param {boolean} props.selected
 * @param {Function} props.onClick
 * @param {number} props.badgeCount
 * @param {import("@mui/material/OverridableComponent").OverridableComponent<import("@mui/material").SvgIconTypeMap<{}, "svg">>} props.Icon
 */
const SettingsCategory = ({ id, category, helperText, selected, onClick, badgeCount = 0, Icon }) => {
  const onClickHandler = () => {
    onClick(id);
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemAvatar>
        <Badge color="primary" badgeContent={badgeCount}>
          <Icon />
        </Badge>
      </ListItemAvatar>
      <ListItemText primary={category} secondary={helperText} />
    </ListItemButton>
  );
};

export const ChartOptions = ({ onListItemSelected, selectedListItemId }) => {
  const gameId = useGameIdFromRoute();

  return (
    <ListView title="Play Test Charts" Filters={null} Action={undefined} EmptyList={EmptyDetailCard} neverEmpty>
      <Box p={1}>
        <Alert severity="info">
          Additional charts can be found by drilling into{" "}
          <AlwaysOnAppLink to={Path.game(gameId).metadata()}>play test metadata</AlwaysOnAppLink>.
        </Alert>
      </Box>
      <SettingsCategory
        category="Score - Composition"
        id="score-composition"
        helperText="View break up of score by parts"
        selected={selectedListItemId === "score-composition"}
        onClick={onListItemSelected}
        badgeCount={0}
        Icon={StackedBarChart}
      />
    </ListView>
  );
};

export const PlayTestChartList = () => {
  const gameId = useGameIdFromRoute();
  const category = useCategoryFromRoute();
  const navigate = useNavigate();

  const onListItemSelected = (id) => {
    navigate(Path.game(gameId).chart(id));
  };

  const SelectedCategory = () => {
    if (category === "score-composition") {
      return <ScoreComposition gameId={gameId} />;
    }

    return <EmptyDetailCard text="Select setting group from list on the left" />;
  };

  const ListView = () => <ChartOptions selectedListItemId={category} onListItemSelected={onListItemSelected} />;

  return <ListDetail ListView={ListView} DetailView={SelectedCategory} />;
};
