import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useCreatePlayTest, useGame, useGamePlayTestMetaData, useImportPlayTest } from "@seabrookstudios/pitch2table";
import { FullScreenDialog } from "../core/FullScreenDialog";
import Box from "@mui/material/Box";
import { Path } from "../Path";
import Alert from "@mui/material/Alert";
import Upload from "@mui/icons-material/Upload";
import { UploadBgStatsFile } from "./UploadBgStatsFile";
import { enqueueSnackbar } from "notistack";
import { formatPlayers2, makePlayer } from "./model";
import { AlwaysOnAppLink } from "../styleguide/AppLink";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { PlayTestMediumLabels } from "@seabrookstudios/pitch2table-core";
import { BgStatsPhysicalLocationSelect } from "./BgStatsPhysicalLocationSelect";
import Pending from "@mui/icons-material/Pending";
import CircularProgress from "@mui/material/CircularProgress";
import Check from "@mui/icons-material/Check";
import { BgStatsGameSelect } from "./BgStatsGameSelect";

/**
 * @param {object} props
 * @param {boolean} props.uploadStarted
 * @param {Promise} props.promise
 */
const Importer = ({ uploadStarted, promise }) => {
  const [showDone, setDone] = useState(false);

  if (!uploadStarted) {
    return <Pending />;
  }

  if (!promise) {
    return <CircularProgress size={16} />;
  }

  promise.then(() => setDone(true));

  return showDone ? <CircularProgress size={16} /> : <Check />;
};

/**
 * @typedef {object} BgStatsGameOption
 * @prop {string} id
 * @prop {string} name
 */

/**
 * @param {object} props
 * @param {string} props.id
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const ImportPlayTestsToGame = ({ gameId, id }) => {
  const { data: game } = useGame(gameId);
  const { data: metadata = [] } = useGamePlayTestMetaData(gameId);
  const [showUpload, setShowUpload] = useState(true);
  const [canSave, setCanSave] = useState(true);
  const [toUpload, setToUpload] = useState(
    /** @type {import("@seabrookstudios/pitch2table-core").ImportPlayTest[]} */ ([])
  );
  const [bgStatsGame, setBgStatsGame] = useState(/** @type {string|null} */ (null));
  const [bgStatsGameData, setBgStatsGameData] = useState({});
  const [bgStatsData, setBgStatsData] = useState(null);
  const [locations, setLocations] = useState([]);
  const [uploadStarted, setUploadedStarted] = useState(false);
  const [promises, setPromises] = useState(/** @type {Promise[]}*/ ([]));
  const createPlayTest = useCreatePlayTest(gameId);
  const importPlayTest = useImportPlayTest(gameId);

  const onSaveHandler = async () => {
    setUploadedStarted(true);

    const p = toUpload.map((request) => {
      return importPlayTest.mutateAsync(request);
    });

    setPromises(p);

    await Promise.all(p);
  };

  const onLocationChange = (ids) => {
    setLocations(ids);
  };

  const onGameSelect = (id) => {
    setBgStatsGame(id);
  };

  const totalId = metadata.find((m) => m.type === "SCORE");

  const processBgStatsGame = () => {
    if (!bgStatsData) {
      return;
    }
    if (!bgStatsGame) {
      return;
    }

    const bgRecord = bgStatsData.games.find((g) => g.id === bgStatsGame);
    setBgStatsGameData(bgRecord);

    const playTests = bgStatsData.plays.filter((p) => p.gameRefId === bgStatsGame);
    const tests = playTests.map((pt) => {
      const outcomes = pt.playerScores.map((ps) => {
        const playerData = bgStatsData.players.find((p) => p.id === ps.playerRefId);

        /**
         * @type {import("@seabrookstudios/pitch2table-core").PlayTestPlayer}
         */
        const player = {
          ...makePlayer(playerData ? playerData.name.trim() : "Unknown"),
          scores: !!totalId && ps.score !== "" ? [{ id: totalId.id, value: Number.parseInt(ps.score, 10) }] : [],
          won: ps.winner,
        };

        return player;
      });

      /**
       * @type {import('@seabrookstudios/pitch2table-core').ImportPlayTest}
       */
      const request = {
        date: DateTime.fromFormat(pt.entryDate, "yyyy-MM-dd hh:mm:ss").toMillis(),
        complete: true,
        ignore: !pt.ignored,
        audience: "FriendsFamily",
        medium: locations.includes(pt.locationRefId) || !pt.locationRefId ? "Physical" : "Digital",
        body: pt.comments && pt.comments.trim().length > 0 ? pt.comments.trim() : undefined,
        duration: pt.durationMin === 0 ? undefined : pt.durationMin,
        rulesVersion: undefined,
        outcomes,
        nonPlayerOutcomes: undefined,
      };

      return request;
    });

    setToUpload(tests);
    setCanSave(false);
  };

  useEffect(() => {
    processBgStatsGame();
  }, [bgStatsData, bgStatsGame, locations]);

  const onSuccess = (data) => {
    if (!game) {
      enqueueSnackbar("An error occurred. Please retry.", { variant: "error" });
      return;
    }

    setShowUpload(false);

    setBgStatsData(data);
  };

  const onFailure = () => {
    enqueueSnackbar(
      "An error occurred attempting to parse the BG Stats exports. Please re-export the file from BG Stats and try again.",
      { variant: "error" }
    );
  };

  return (
    <FullScreenDialog
      id={id}
      minWidth="80%"
      title="Import play tests from BG Stats"
      actionText="IMPORT"
      onSaveHandler={onSaveHandler}
      canSave={canSave}
      isBusy={createPlayTest.isPending}
      Icon={Upload}
    >
      {!totalId && (
        <Box pb={1}>
          <Alert severity="warning">
            The game does not have{" "}
            <AlwaysOnAppLink to={Path.game(gameId).metadata()}>tracking data for scores</AlwaysOnAppLink>. Without this,
            you will only be able to upload who won.
          </Alert>
        </Box>
      )}
      {bgStatsGame && game && bgStatsGameData && game.name !== bgStatsGameData.name && (
        <Box pb={1}>
          <Alert severity="warning">
            The name of the game in the BG Stags file "{bgStatsGameData.name}" does not match this game "{game.name}".
          </Alert>
        </Box>
      )}
      {showUpload && <UploadBgStatsFile onSuccess={onSuccess} onFailure={onFailure} />}
      {bgStatsData && (
        <List>
          <BgStatsGameSelect options={bgStatsData.games} value={bgStatsGame} onChange={onGameSelect} />
        </List>
      )}
      {bgStatsData && (
        <List>
          <BgStatsPhysicalLocationSelect
            options={bgStatsData.locations}
            value={locations}
            onChange={onLocationChange}
          />
        </List>
      )}
      {bgStatsGame && (
        <List>
          <Typography>Found: {toUpload.length}</Typography>
          {toUpload.map((request, i) => (
            <ListItem>
              <ListItemAvatar>
                <Stack direction="column">
                  <Typography textAlign="left" variant="body2">
                    {DateTime.fromMillis(request.date).toFormat("dd MMM")}
                  </Typography>
                  <Typography textAlign="left" variant="body1">
                    {DateTime.fromMillis(request.date).toFormat("yyyy")}
                  </Typography>
                </Stack>
              </ListItemAvatar>
              <ListItemText
                primary={formatPlayers2(request.outcomes || [])}
                secondary={`${PlayTestMediumLabels[request.medium]} (${
                  (request.outcomes || []).filter((o) => o.scores.length > 0).length > 0 ? "has scores" : "no scores"
                })`}
              />
              <ListItemSecondaryAction>
                <Importer uploadStarted={uploadStarted} promise={promises[i]} />
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      )}
    </FullScreenDialog>
  );
};
