import React, { useState } from "react";
import { DateTime } from "luxon";
import { useCreatePlayTest, useCreatePlayTestMetaData } from "@seabrookstudios/pitch2table";
import { FullScreenDialog } from "../core/FullScreenDialog";
import { SmartNumberFieldWithUndefinedSupport } from "../core/SmartNumberField";
import { EditableSwitch } from "../core/EditableSwitch";
import { RulesVersionSelect } from "../games/publisher-interest/PublisherInterestRulesSentSelect";
import { Editor } from "../core/Editor";
import { MediumSelect } from "./MediumSelect";
import { AudienceSelect } from "./AudienceSelect";
import Box from "@mui/material/Box";
import { Heading } from "../core/Heading";
import Stack from "@mui/material/Stack";
import { SmartDateField } from "../core/SmartDateField";
import { MetadataTypeSelect } from "./MetadataTypeSelect";
import { SmartTextField } from "../core/SmartTextField";
import { useNavigate } from "react-router-dom";
import { Path } from "../Path";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

/**
 * @param {object} props
 * @param {string} props.id
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const AddPlayTestToGame = ({ gameId, id }) => {
  const navigate = useNavigate();

  /**
   * @param {import("@seabrookstudios/pitch2table-core").PlayTest} pt
   */
  const redirectOnComplete = (pt) => {
    navigate(Path.game(gameId).test(pt.id));
  };

  const createPlayTest = useCreatePlayTest(gameId, redirectOnComplete);

  const [duration, setDuration] = useState(/** @type {number|undefined} */ (undefined));
  const [complete, setCompleted] = useState(true);
  const [include, setInclude] = useState(true);
  const [body, setBody] = useState("");
  const [date, setDate] = useState(DateTime.now().toMillis());
  const [rulesVersion, setRulesId] = useState(
    /** @type {import("@seabrookstudios/pitch2table-core").AttachmentId|undefined} */ (undefined)
  );
  const [medium, setMedium] = useState(
    /** @type {import("@seabrookstudios/pitch2table-core").PlayTestMedium} */ ("Physical")
  );
  const [audience, setAudience] = useState(
    /** @type {import("@seabrookstudios/pitch2table-core").PlayTestAudience|undefined} */ (undefined)
  );

  const onSaveHandler = async () => {
    await createPlayTest.mutateAsync({
      date,
      duration,
      complete,
      rulesVersion,
      ignore: !include,
      body,
      medium,
      audience,
    });
  };

  return (
    <FullScreenDialog
      id={id}
      title="Add play test"
      actionText="SAVE"
      onSaveHandler={onSaveHandler}
      canSave={false}
      isBusy={createPlayTest.isPending}
    >
      <Alert severity="info">Results can be added after saving.</Alert>
      <Stack direction="row" pt={1}>
        <Box flex={1} pt={1}>
          <SmartDateField
            label="Date"
            initialValue={date}
            onChange={setDate}
            helperText="Date the play test occurred"
          />
        </Box>
        <Box flex={1} pl={1}>
          <SmartNumberFieldWithUndefinedSupport
            id="topic-duration"
            label="Duration"
            initialValue={duration}
            onChange={setDuration}
            helperText="How long did the game go for (in minutes)?"
          />
        </Box>
      </Stack>
      <Stack direction="row">
        <Box flex={1}>
          <EditableSwitch
            initialValue={complete}
            title="Completed"
            offLabel="This play test is starting now."
            onLabel="This play test is complete."
            onUpdate={setCompleted}
          />
        </Box>
        <Box flex={1}>
          <EditableSwitch
            initialValue={include}
            title="Include in statistics"
            onLabel="This play test will be included in statistics."
            offLabel="This play test won't be used for statistics."
            onUpdate={setInclude}
          />
        </Box>
      </Stack>
      <AudienceSelect
        value={audience}
        onChange={setAudience}
        helperText="Broadly speaking, how would you classify the play testers?"
      />
      <MediumSelect value={medium} onChange={setMedium} helperText="Is this an in-person or virtual play test?" />
      <RulesVersionSelect
        label="Rules Version for Test"
        gameId={gameId}
        value={rulesVersion}
        onChange={setRulesId}
        helperText="The rules version used for this play test. Use the comments section below to note impromptu rule changes."
      />
      <Box pt={2}>
        <Box pl={1} pb={1}>
          <Heading>Rule changes, notes and other comments.</Heading>
        </Box>
        <Editor value={body} onChange={setBody} />
      </Box>
    </FullScreenDialog>
  );
};

/**
 * @param {object} props
 * @param {string} props.id
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const AddPlayTestMetadataToGame = ({ gameId, id }) => {
  const createPlayTestMetadata = useCreatePlayTestMetaData(gameId);

  const [name, setName] = useState("");
  const [type, setType] = useState(
    /** @type {import("@seabrookstudios/pitch2table-core").PlayTestMetadataComponentType} */ ("PART_OF_SCORE")
  );

  const onSaveHandler = async () => {
    await createPlayTestMetadata.mutateAsync({
      name,
      type,
    });
  };

  return (
    <FullScreenDialog
      id={id}
      title={"Add tracking variable"}
      actionText="SAVE"
      onSaveHandler={onSaveHandler}
      canSave={false}
      isBusy={createPlayTestMetadata.isPending}
    >
      <SmartTextField
        id="metadata-name"
        label="Name"
        initialValue={name}
        onChange={setName}
        multiline={false}
        helperText="The name of the tracking variable e.g. score, trade action, etc"
      />
      <MetadataTypeSelect
        value={type}
        onChange={setType}
        helperText="How is this value tracked, per game, per player or part of the score?"
      />
    </FullScreenDialog>
  );
};

/**
 * @param {object} props
 * @param {string} props.id
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const AutoAddPlayTestToGame = ({ gameId, id }) => {
  const navigate = useNavigate();

  /**
   * @param {import("@seabrookstudios/pitch2table-core").PlayTest} playTest
   */
  const onSuccess = (playTest) => {
    navigate(Path.game(gameId).test(playTest.id));
  };

  const createPlayTest = useCreatePlayTest(gameId, onSuccess);

  const onSaveHandler = async () => {
    await createPlayTest.mutateAsync({
      date: DateTime.now().toMillis(),
      duration: undefined,
      complete: undefined,
      rulesVersion: undefined,
      ignore: false,
      body: undefined,
      medium: "Physical",
      audience: undefined,
    });
  };

  return (
    <FullScreenDialog
      id={id}
      title="Add play test"
      actionText="SAVE"
      onSaveHandler={onSaveHandler}
      canSave={false}
      isBusy={createPlayTest.isPending}
    >
      <CircularProgress />
    </FullScreenDialog>
  );
};
