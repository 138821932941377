import React from "react";
import { DateTime } from "luxon";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardMedia from "@mui/material/CardMedia";
import CircularProgress from "@mui/material/CircularProgress";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import { EmptyDetailCard } from "../../core/EmptyDetailCard";
import { useGameIdFromRoute, useUserIdIdFromRoute } from "../WithGameIdFromRoute";
import { useContact, useGame, useInvitesForGame, useRemoveUser, useWithdrawInvite } from "@seabrookstudios/pitch2table";
import { useNavigate } from "react-router-dom";
import { Path } from "../../Path";
import { formatContactLocation } from "@seabrookstudios/pitch2table-core";
import { useUserProfile } from "../../user/queries";
import { useDocumentTitle } from "usehooks-ts";
import { formatFullName } from "../../contacts/model";
import Alert from "@mui/material/Alert";
import { AlwaysOnAppLink } from "../../styleguide/AppLink";

/**
 * @param {object} props
 * @param {import('@seabrookstudios/types').GameId} props.gameId
 * @param {import('@seabrookstudios/types').UserId} props.userId
 */
const RemoveCoDesignerButton = ({ gameId, userId }) => {
  const { data: profile } = useUserProfile();
  const { data: game } = useGame(gameId);
  const navigate = useNavigate();

  const onSuccess = () => {
    navigate(Path.game(gameId).designers());
  };

  const removeUser = useRemoveUser(gameId, onSuccess);

  const onClickHandler = () => {
    removeUser.mutate({ userId });
  };

  const role = (
    game ? game.designers : /** @type {import("@seabrookstudios/pitch2table-core").GameDesigners[]} */ ([])
  ).find((d) => d.designerId === userId);

  const isSelf = !profile || userId === profile.id;
  const isOwner = role ? role.role === "owner" || role.role === undefined : false;

  return (
    <Button disabled={isSelf || isOwner} onClick={onClickHandler}>
      {removeUser.isPending ? <CircularProgress size={16} /> : "Remove Co-designer"}
    </Button>
  );
};

/**
 * @param {object} props
 * @param {import('@seabrookstudios/types').GameId} props.gameId
 * @param {import('@seabrookstudios/pitch2table-core').InviteId} props.inviteId
 */
const WithdrawInviteButton = ({ gameId, inviteId }) => {
  const navigate = useNavigate();

  const onSuccess = () => {
    navigate(Path.game(gameId).designers());
  };

  const withdrawInvite = useWithdrawInvite(gameId, onSuccess);

  const onClickHandler = () => {
    withdrawInvite.mutate({ inviteId });
  };

  return (
    <Button onClick={onClickHandler}>
      {withdrawInvite.isPending ? <CircularProgress size={16} /> : "Withdraw Invite"}
    </Button>
  );
};

/**
 * @param {object} props
 * @param {import('@seabrookstudios/types').GameId} props.gameId
 * @param {import('@seabrookstudios/types').UserId} props.userId
 */
const RemoveOrWithdrawInviteButton = ({ gameId, userId }) => {
  const { data: invites = [] } = useInvitesForGame(gameId);

  const hasInvite = invites.find((invite) => invite.invitee === userId);

  return !!hasInvite ? (
    <WithdrawInviteButton gameId={gameId} inviteId={hasInvite.id} />
  ) : (
    <RemoveCoDesignerButton gameId={gameId} userId={userId} />
  );
};

/**
 *
 * @param {object} props
 * @param {import('@seabrookstudios/types').GameId} props.gameId
 * @param {import('@seabrookstudios/types').UserId} props.userId
 */
const DesignerDetail = ({ gameId, userId }) => {
  const { data: contact, isFetching } = useContact(userId);
  const { data: thisUser } = useUserProfile();

  useDocumentTitle(contact ? formatFullName(contact) : "Pitch2Table");

  if (isFetching) {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  if (!contact) {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <ErrorOutline />
        </Box>
      </Card>
    );
  }

  const contactTime = DateTime.now().setZone(contact.location.timezone);

  return (
    <Card elevation={1} square={false}>
      {!!thisUser && thisUser.id === userId && (
        <Box p={1}>
          <Alert severity="info">
            This is you.{" "}
            <AlwaysOnAppLink to={Path.settings().category("about-you")}>
              Click here to update your profile information
            </AlwaysOnAppLink>
            .
          </Alert>
        </Box>
      )}
      <Box p={1}>
        <CardMedia
          component="img"
          sx={{ maxHeight: 200, objectFit: "contain" }}
          image={contact.pictureUrl}
          title={`Profile picture for ${contact.preferredName || contact.firstName}`}
        />
      </Box>
      <List>
        <ListItem>
          <ListItemText primary="Name" secondary={formatFullName(contact)} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Preferred Name" secondary={contact.preferredName} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Pronouns" secondary={contact.pronouns} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Location" secondary={formatContactLocation(contact.location)} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Time Zone" secondary={contact.location.timezone} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Current Time" secondary={contactTime.toFormat("DDD @ TTT")} />
        </ListItem>
      </List>
      <CardActions>
        <RemoveOrWithdrawInviteButton gameId={gameId} userId={userId} />
      </CardActions>
    </Card>
  );
};

export const GameDesignerFromRoute = () => {
  const gameId = useGameIdFromRoute();
  const userId = useUserIdIdFromRoute();

  if (!userId) {
    return <EmptyDetailCard text="Select a designer from the list on the left." />;
  }

  return <DesignerDetail gameId={gameId} userId={userId} />;
};
