import React, { useState } from "react";
import { useCreateRoyaltyEvent } from "@seabrookstudios/pitch2table";
import { DatePicker } from "@mui/x-date-pickers";
import { DateTime } from "luxon";
import { FullScreenDialog } from "../../core/FullScreenDialog";
import { SmartNumberField } from "../../core/SmartNumberField";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CurrentDropDown } from "../../currency/CurrencyDropDown";
import { ContractSelect } from "../contracts/ContractSelect";
import { RoyaltyTypeSelect } from "./RoyaltyTypeSelect";
import { PublisherSelect } from "./PublisherSelect";

/**
 * @param {object} props
 * @param {number} props.initialValue
 * @param {(value: number) => void} props.onChange
 * @param {string} props.label
 * @param {string} props.helperText
 */
const SmartDateField = ({ initialValue = DateTime.now().toMillis(), onChange, label, helperText }) => {
  const [dueDateObj, setDueDateObj] = useState(DateTime.fromMillis(initialValue));

  /**
   * @param {DateTime} componentDate
   */
  const onUpdateDate = (componentDate) => {
    setDueDateObj(componentDate);
    onChange(componentDate.toMillis());
  };

  return (
    <Stack direction="column">
      <DatePicker defaultValue={dueDateObj} label={label} onChange={onUpdateDate} />
      <Typography color="text.secondary" variant="caption" sx={{ marginTop: "3px", marginLeft: "14px" }}>
        {helperText}
      </Typography>
    </Stack>
  );
};

/**
 * @param {object} props
 * @param {string} props.id
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export default function AddRoyaltyAction({ id, gameId }) {
  const createRoyaltyEvent = useCreateRoyaltyEvent(gameId);

  const [amount, setAmount] = useState(0);
  const [currency, setCurrency] = useState(/** @type {import("@seabrookstudios/pitch2table-core").Currency} */ ("USD"));
  const [date, setDate] = useState(DateTime.now().toMillis());
  const [paid, setPaid] = useState(false);
  const [type, setType] = useState(/** @type {import("@seabrookstudios/pitch2table-core").RoyaltyType} */ ("Royalty"));
  const [contractId, setContractId] = useState(
    /** @type {import("@seabrookstudios/pitch2table-core").AttachmentId|undefined} */ (undefined)
  );
  const [publisherId, setPublisherId] = useState(
    /** @type {import("@seabrookstudios/pitch2table-core").PublisherId|undefined} */ (undefined)
  );

  const onSaveHandler = async () => {
    await createRoyaltyEvent.mutateAsync({ amount, currency, date, type, contractId, paid, publisherId });
  };

  return (
    <FullScreenDialog
      id={id}
      title="Add Royalty Payment"
      actionText="CREATE"
      onSaveHandler={onSaveHandler}
      canSave={false}
      isBusy={createRoyaltyEvent.isPending}
    >
      <Alert severity="info">
        Add a royalty payment (or schedule a payment reminder). All of these fields are optional, providing more
        information will allow better reporting. You may only enter royalty payments for yourself.
      </Alert>
      <Stack direction="row">
        <Box pt={1} flex={1}>
          <SmartNumberField
            id="royalty-amount"
            label="Amount"
            helperText={paid ? "The amount of the payment." : "The expected amount of the payment."}
            onChange={setAmount}
          />
        </Box>
        <Box p={1} />
        <Box flex={1}>
          <CurrentDropDown value={currency} onChange={setCurrency} helperText="The currency of the payment." />
        </Box>
      </Stack>
      <Box p={1} />
      <SmartDateField
        label={paid ? "Date Paid" : "Due Date"}
        initialValue={date}
        onChange={setDate}
        helperText={paid ? "When the payment was paid." : "When the payment is due."}
      />
      <Box p={1} />
      <RoyaltyTypeSelect
        value={type}
        onChange={setType}
        helperText="What kind of royalty payment is this e.g. Advance, Signing Bonus, Royalty"
      />
      <Box p={1} />
      <ContractSelect
        gameId={gameId}
        value={contractId}
        onChange={setContractId}
        helperText="Which contract is this payment in relation to."
      />
      <Box p={1} />
      <PublisherSelect
        gameId={gameId}
        value={publisherId}
        onChange={setPublisherId}
        helperText="Which publisher did this payment come from?"
      />
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={paid} onChange={() => setPaid((x) => !x)} />}
          label="Have you received this payment? (if no, you will get a reminder when this payment comes due)"
        />
      </FormGroup>
    </FullScreenDialog>
  );
}
