import React from "react";
import { NoRules, RulesListEntry2 } from "./RulesList";
import { RulesFromRoute } from "./RulesPreview";
import { useGameIdFromRoute } from "../games/WithGameIdFromRoute";
import { useGameRules } from "@seabrookstudios/pitch2table";
import { Path } from "../Path";
import { idExtractor, useMakeListAndDetail, useMakeListBuilder } from "../core/useMakeLayout";
import AddRulesFullScreenDialog from "./AddRulesFullScreenDialog";

export const RulesListAndDetail = () => {
  const gameId = useGameIdFromRoute();

  const Action = () => <AddRulesFullScreenDialog gameId={gameId} />;
  const useWrappedQuery = () => useGameRules(gameId);

  const useMakeList = useMakeListBuilder(
    RulesListEntry2,
    idExtractor,
    "Rules",
    NoRules,
    Action,
    undefined,
    Path.game(gameId).link()
  );

  const ListAndDetail = useMakeListAndDetail(useWrappedQuery, [], "game-rules", useMakeList, RulesFromRoute);

  return <ListAndDetail />;
};
