import { useAllRoyalties } from "@seabrookstudios/pitch2table";
import { useQuery } from "@tanstack/react-query";
import { royaltyToYear } from "./model";

export const useAllRoyaltiesByGame = () => {
  const { data: royalties = [] } = useAllRoyalties();

  return useQuery({
    queryKey: ["royalties-by-game"],
    /**
     * @returns {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp[][]}
     */
    queryFn: () => {
      const gameIds = royalties
        .map((r) => r.gameId)
        .reduce(
          (acc, v) => (acc.includes(v) ? acc : [...acc, v]),
          /** @type {import("@seabrookstudios/types").GameId[]} */ ([])
        );

      return gameIds.map((gameId) => royalties.filter((r) => r.gameId === gameId));
    },
    staleTime: 5000,
  });
};

export const useAllRoyaltiesByPublisher = () => {
  const { data: royalties = [] } = useAllRoyalties();

  return useQuery({
    queryKey: ["royalties-by-publisher"],
    /**
     * @returns {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp[][]}
     */
    queryFn: () => {
      const gameIds = royalties
        .map((r) => r.publisherId)
        .reduce(
          (acc, v) => (acc.includes(v) ? acc : [...acc, v]),
          /** @type {import("@seabrookstudios/pitch2table-core").PublisherId[]} */ ([])
        );

      return gameIds.map((publisherId) => royalties.filter((r) => r.publisherId === publisherId));
    },
    staleTime: 5000,
  });
};
export const useAllRoyaltiesByType = () => {
  const { data: royalties = [] } = useAllRoyalties();

  return useQuery({
    queryKey: ["royalties-by-type"],
    /**
     * @returns {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp[][]}
     */
    queryFn: () => {
      const gameIds = royalties
        .map((r) => r.type)
        .reduce(
          (acc, v) => (acc.includes(v) ? acc : [...acc, v]),
          /** @type {import("@seabrookstudios/pitch2table-core").RoyaltyType[]} */ ([])
        );

      return gameIds.map((type) => royalties.filter((r) => r.type === type));
    },
    staleTime: 5000,
  });
};

export const useAllRoyaltiesByYear = () => {
  const { data: royalties = [] } = useAllRoyalties();

  return useQuery({
    queryKey: ["royalties-by-year"],
    /**
     * @returns {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp[][]}
     */
    queryFn: () => {
      const years = royalties.map(royaltyToYear).reduce((acc, v) => (acc.includes(v) ? acc : [...acc, v]), []);

      const byYear = years.map((year) => royalties.filter((r) => royaltyToYear(r) === year));

      byYear.sort((a, b) => royaltyToYear(a[0]) - royaltyToYear(b[0]));

      return byYear;
    },
    staleTime: 5000,
  });
};

/**
 * @param {string|undefined} year
 */
export const useAllRoyaltiesForYear = (year) => {
  const { data: royalties = [] } = useAllRoyalties();

  return useQuery({
    queryKey: ["royalties-by-year", year],
    /**
     * @returns {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp[]}
     */
    queryFn: () => {
      return royalties.filter((r) => royaltyToYear(r) === year);
    },
    staleTime: 5000,
    placeholderData: [],
    enabled: year !== undefined,
  });
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").RoyaltyType|undefined} type
 */
export const useAllRoyaltiesForType = (type) => {
  const { data: royalties = [] } = useAllRoyalties();

  return useQuery({
    queryKey: ["royalties-by-type", type],
    /**
     * @returns {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp[]}
     */
    queryFn: () => {
      return royalties.filter((r) => r.type === type);
    },
    staleTime: 5000,
    placeholderData: [],
    enabled: type !== undefined,
  });
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId|"unlinked"} publisherId
 */
export const useAllRoyaltiesForPublisher = (publisherId) => {
  const { data: royalties = [] } = useAllRoyalties();

  return useQuery({
    queryKey: ["royalties-by-publisher", publisherId],
    /**
     * @returns {import("@seabrookstudios/pitch2table-core").RoyaltyEventForApp[]}
     */
    queryFn: () => {
      return royalties.filter((r) => r.publisherId === (publisherId === "unlinked" ? undefined : publisherId));
    },
    staleTime: 5000,
    placeholderData: [],
    enabled: publisherId !== undefined,
  });
};
