import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";

const Label = "Physical Locations";

/**
 * @param {object} props
 * @param {{id: string, name: string}[]} props.options
 * @param {string[]} props.value
 * @param {(ids: string[]) => void} props.onChange
 */
export const BgStatsPhysicalLocationSelect = ({ options = [], value = [], onChange }) => {
  const [selected, setSelected] = useState(value);

  const handleChange = (event) => {
    const { value } = event.target;

    setSelected(typeof value === "string" ? value.split(",") : value);
  };

  const handleClose = async () => {
    await onChange(selected);
  };

  return (
    <FormControl sx={{ width: "100%" }} margin="normal">
      <InputLabel id="bg-stats-import-location-label">{Label}</InputLabel>
      <Select
        id="bg-stats-import-location"
        labelId="bg-stats-import-location-label"
        value={selected}
        multiple
        onChange={handleChange}
        onClose={handleClose}
        placeholder={Label}
        label={Label}
        input={<OutlinedInput label={Label} />}
        renderValue={(items = []) => {
          return options
            .filter((o) => items.includes(o.id))
            .map((o) => o.name)
            .join(", ");
        }}
      >
        {options.map((d) => (
          <MenuItem key={d.id} value={d.id}>
            <Checkbox id={d.id} checked={selected.includes(d.id)} />
            <ListItemText primary={d.name} />
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        Tests with no location and the selected locations will mark the tests as being "In-Person" all other tests will
        be "Online/Virtual"
      </FormHelperText>
    </FormControl>
  );
};
