import React, { useCallback, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

/**
 * @param {object} props
 * @param {string} [props.id]
 * @param {number} [props.value]
 * @param {number} [props.initialValue]
 * @param {(text: number) => void} props.onChange
 * @param {(text: number) => void} [props.onEnter]
 * @param {boolean} [props.onEnterDisabled]
 * @param {boolean} [props.multiline]
 * @param {boolean} [props.autoFocus]
 * @param {number} [props.minRows]
 * @param {boolean} [props.required]
 * @param {string} props.label
 * @param {string} [props.helperText]
 */
export const SmartNumberField = ({
  initialValue = 0,
  id,
  value,
  onChange,
  onEnterDisabled = true,
  onEnter,
  multiline = false,
  autoFocus = false,
  required = false,
  helperText = "",
  minRows = 1,
  label,
  ...props
}) => {
  const [newValue, setNewValue] = useState(/** @type {number|undefined} */ (initialValue));
  const [changed, setHasChanged] = useState(false);
  const [isValid, setValidStatus] = useState(true);

  const onChangeHandler = (ev) => {
    if (ev.target.value.length === 0) {
      setNewValue(undefined);
      return;
    }
    const asNumber = Number.parseFloat(ev.target.value);
    if (Number.isNaN(asNumber)) {
      setNewValue(newValue);
      return;
    }

    setHasChanged(true);
    setNewValue(Number.parseFloat(ev.target.value));
  };

  const onKeyDownHandler = (ev) => {
    if (onEnterDisabled) {
      return;
    }
    if (ev.key !== "Enter") {
      return;
    }

    if (onEnter) {
      onEnter(newValue || 0);
    }
  };

  const validate = useCallback(() => {
    onChange(newValue || 0);
    setValidStatus(true);
    return true;
  }, [setValidStatus, newValue, onChange]);

  useEffect(() => {
    if (!changed) {
      return;
    }

    validate();
  }, [newValue, validate, changed]);

  return (
    <TextField
      id={id}
      defaultValue={initialValue}
      fullWidth
      margin="dense"
      multiline={multiline}
      minRows={minRows}
      autoFocus={autoFocus}
      label={label}
      required={required}
      helperText={helperText}
      value={newValue}
      {...props}
      error={required && !isValid}
      onChange={onChangeHandler}
      onKeyDown={onKeyDownHandler}
      type="number"
    />
  );
};

/**
 * @param {object} props
 * @param {string} [props.id]
 * @param {number|undefined} [props.value]
 * @param {number|undefined} [props.initialValue]
 * @param {(text: (number|undefined)) => void} props.onChange
 * @param {(text: (number|undefined)) => void} [props.onEnter]
 * @param {boolean} [props.onEnterDisabled]
 * @param {boolean} [props.multiline]
 * @param {boolean} [props.autoFocus]
 * @param {number} [props.minRows]
 * @param {boolean} [props.required]
 * @param {string} props.label
 * @param {string} [props.helperText]
 */
export const SmartNumberFieldWithUndefinedSupport = ({
  initialValue = undefined,
  id,
  value,
  onChange,
  onEnterDisabled = true,
  onEnter,
  multiline = false,
  autoFocus = false,
  required = false,
  helperText = "",
  minRows = 1,
  label,
  ...props
}) => {
  const [newValue, setNewValue] = useState(/** @type {number|undefined} */ (initialValue));
  const [changed, setHasChanged] = useState(false);
  const [isValid, setValidStatus] = useState(true);

  const onChangeHandler = (ev) => {
    if (ev.target.value.length === 0) {
      setNewValue(undefined);
      return;
    }
    const asNumber = Number.parseFloat(ev.target.value);
    if (Number.isNaN(asNumber)) {
      setNewValue(newValue);
      return;
    }

    setHasChanged(true);
    setNewValue(Number.parseFloat(ev.target.value));
  };

  const onKeyDownHandler = (ev) => {
    if (onEnterDisabled) {
      return;
    }
    if (ev.key !== "Enter") {
      return;
    }

    if (onEnter) {
      onEnter(newValue || undefined);
    }
  };

  const validate = useCallback(() => {
    onChange(newValue || undefined);
    setValidStatus(true);
    return true;
  }, [setValidStatus, newValue, onChange]);

  useEffect(() => {
    if (!changed) {
      return;
    }

    validate();
  }, [newValue, validate, changed]);

  return (
    <TextField
      id={id}
      defaultValue={initialValue}
      fullWidth
      margin="dense"
      multiline={multiline}
      minRows={minRows}
      autoFocus={autoFocus}
      label={label}
      required={required}
      helperText={helperText}
      value={newValue}
      {...props}
      error={required && !isValid}
      onChange={onChangeHandler}
      onKeyDown={onKeyDownHandler}
      type="number"
    />
  );
};
