import React from "react";
import { GameDetailFromRoute } from "./GameDetail";
import { useMyGames } from "@seabrookstudios/pitch2table";
import { idExtractor, useMakeListAndDetail, useMakeListBuilder } from "../core/useMakeLayout";
import { NoItemsFound } from "../publisher/NoItemsFound";
import Typography from "@mui/material/Typography";
import { GameListEntry } from "./ListItem";
import AddGameFullScreenDialog from "./AddGameFullScreenDialog";
import { GamesListFilters } from "./filters";

const NoGamesFound = () => (
  <NoItemsFound>
    <Typography align="center">No games found.</Typography>
  </NoItemsFound>
);

export const GamesListAndDetail = () => {
  const useMakeList = useMakeListBuilder(GameListEntry, idExtractor, "My Games", NoGamesFound, AddGameFullScreenDialog);

  const ListAndDetail = useMakeListAndDetail(useMyGames, GamesListFilters, "games", useMakeList, GameDetailFromRoute);

  return <ListAndDetail />;
};
