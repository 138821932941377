import React from "react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";

registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginFileValidateSize);

/**
 * @param {object} props
 * @param {() => void} props.onFailure
 * @param {(f: object) => void} props.onSuccess
 */
export const UploadBgStatsFile = ({ onSuccess, onFailure }) => {
  const process = async (_fieldName, file, _metadata, _load, _error, _progress, abort) => {
    const reader = new FileReader();

    reader.addEventListener("load", (e) => {
      if (!e.target) {
        onFailure();
        return;
      }
      if (!e.target.result) {
        onFailure();
        return;
      }

      try {
        // @ts-ignore
        const p = JSON.parse(e.target.result);
        onSuccess(p);
      } catch (err) {
        onFailure();
      }
    });

    reader.readAsText(file);

    return {
      abort: () => {
        onFailure();
        abort();
      },
    };
  };

  return (
    <FilePond
      allowMultiple={false}
      allowReplace={false}
      allowRevert={false}
      allowProcess={false}
      credits={false}
      instantUpload={true}
      maxFiles={1}
      acceptedFileTypes={["application/json"]}
      labelIdle={'Drag & Drop your BGStatsExport.json or <span class="filepond--label-action"> Browse </span>'}
      maxFileSize="10MB"
      server={{
        process,
      }}
    />
  );
};
