import React from "react";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, useParams } from "react-router-dom";
import { Path } from "../Path";
import { PublisherStatus } from "./PublisherStatus";
import { NoItemsFound } from "../publisher/NoItemsFound";
import { PublisherLogoSquare } from "../publisher/PublisherLogo";
import { formatFollowUp, showAlarm } from "@seabrookstudios/pitch2table-core";
import { FollowUpDuePill } from "./FollowUpDuePill";
import { getSeverity } from "./publisher-interest/follow-up-severity";

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/pitch2table-core").DesignerPublisherGameView} props.item
 */
export const PublisherListEntry2 = ({ item }) => {
  const params = useParams();
  const navigate = useNavigate();

  const selected = params.publisherId === item.publisherId;

  const onClickHandler = () => {
    navigate(Path.game(item.gameId).publisher(item.publisherId));
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemAvatar>
        <PublisherLogoSquare publisher={item} />
      </ListItemAvatar>
      <ListItemText primary={item.publisherName} secondary={formatFollowUp(item)} />
      {showAlarm(item.nextAction) && <FollowUpDuePill severity={getSeverity(item.nextAction)} />}
      <Box p={0.5} />
      <ListItemIcon>
        <PublisherStatus status={item.status} />
      </ListItemIcon>
    </ListItemButton>
  );
};

export const NoPublisherInterest = () => (
  <NoItemsFound>
    <Alert severity="info">
      Track your interactions with each publisher. Start with a shortlist and then update each publisher after you've
      contacted them. Use in the in-built reminder system to know when it's time to reach out for an update.
    </Alert>
  </NoItemsFound>
);
