import React, { useState } from "react";
import { useGamePlayTestMetaData, useUpdatePlayTest } from "@seabrookstudios/pitch2table";
import { FullScreenDialog } from "../core/FullScreenDialog";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Star from "@mui/icons-material/Star";
import Tooltip from "@mui/material/Tooltip";
import StarOutline from "@mui/icons-material/StarOutline";
import CardHeader from "@mui/material/CardHeader";
import IconButton from "@mui/material/IconButton";
import { SmartTextField } from "../core/SmartTextField";
import PersonAdd from "@mui/icons-material/PersonAdd";
import PersonRemove from "@mui/icons-material/PersonRemove";
import Edit from "@mui/icons-material/Edit";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import Chip from "@mui/material/Chip";
import { PlayTestMetadataComponentTypeLabels } from "@seabrookstudios/pitch2table-core";
import Divider from "@mui/material/Divider";
import { getNonPlayerValue, getValueForMetaForPlayer, makePlayer, updateValueForMetaForPlayer } from "./model";
import Alert from "@mui/material/Alert";

/**
 * @param {object} props
 * @param {string} props.id
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestId} props.playTestId
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestPlayer[]} props.outcomes
 * @param {import("@seabrookstudios/pitch2table-core").PlayTestOutcomes[]} props.nonPlayerOutcomes
 * @param {number} props.minPlayers
 * @param {number|undefined} props.maxPlayers
 * @param {boolean} [props.editMode]
 */
export const AddScoresToPlayTest = ({
  gameId,
  id,
  playTestId,
  editMode = false,
  outcomes = [],
  nonPlayerOutcomes = [],
  minPlayers,
  maxPlayers,
}) => {
  const updatePlayTest = useUpdatePlayTest(gameId, playTestId);
  const { data: metadata = [] } = useGamePlayTestMetaData(gameId);
  const [localOutcomes, setLocalOutcomes] = useState(
    outcomes.length === 0
      ? Array(minPlayers)
          .fill(0)
          .map((_, i) => i + 1)
          .map((i) => makePlayer(`Anonymous ${i}`))
      : outcomes
  );
  const [localNonPlayerOutcomes, setNonPlayerOutcomes] = useState(nonPlayerOutcomes);
  const [open, setOpen] = useState({});

  const addPlayer = () => {
    setLocalOutcomes((p) => p.concat(makePlayer(`Anonymous ${p.length + 1}`)));
  };

  const removePlayer = (id) => () => {
    setLocalOutcomes((p) => p.filter((pp) => pp.id !== id));
  };

  /**
   * @param {import("@seabrookstudios/pitch2table-core").PlayTestMetadataComponentId} metaId
   */
  const setNonPlayerValue = (metaId) => (value) => {
    setNonPlayerOutcomes((np) => {
      const index = np.findIndex((pto) => pto.id === metaId);
      const b4 = np.filter((_, i) => i < index);
      const a4 = np.filter((_, i) => i > index);

      const updated = { id: metaId, value };

      return [...b4, updated, ...a4];
    });
  };

  /**
   * @param {import("@seabrookstudios/pitch2table-core").PlayTestPlayer} player
   * @param {import("@seabrookstudios/pitch2table-core").PlayTestMetadataComponentId} metaId
   */
  const onPlayerMetaValueChangeHandler = (player, metaId) => (value) => {
    setLocalOutcomes((p) => {
      return updateValueForMetaForPlayer(p, player, metaId, value);
    });
  };

  /**
   * @param {string} id
   */
  const updatePlayerName = (id) => {
    /**
     * @param {string} newName
     */
    const onSaveHandler = (newName) => {
      setLocalOutcomes((p) => {
        const index = p.findIndex((pl) => pl.id === id);
        const b4 = p.filter((_, i) => i < index);
        const a4 = p.filter((_, i) => i > index);

        const updatedPlayer = {
          id,
          name: newName,
          scores: p[index].scores,
          won: p[index].won,
        };

        return [...b4, updatedPlayer, ...a4];
      });
    };

    return onSaveHandler;
  };

  /**
   * @param {string} id
   */
  const togglePlayerWon = (id) => {
    const onSaveHandler = () => {
      setLocalOutcomes((p) => {
        const index = p.findIndex((pl) => pl.id === id);
        const b4 = p.filter((_, i) => i < index);
        const a4 = p.filter((_, i) => i > index);

        const updatedPlayer = {
          id,
          name: p[index].name,
          scores: p[index].scores,
          won: !p[index].won,
        };

        return [...b4, updatedPlayer, ...a4];
      });
    };

    return onSaveHandler;
  };
  /**
   * @param {string} id
   */
  const toggleOpen = (id) => {
    const toggleHandler = () => {
      setOpen((current) => ({ ...current, [id]: !current[id] }));
    };

    return toggleHandler;
  };

  const onSaveHandler = async () => {
    await updatePlayTest.mutate({
      date: undefined,
      audience: undefined,
      body: undefined,
      complete: undefined,
      duration: undefined,
      ignore: undefined,
      medium: undefined,
      outcomes: localOutcomes,
      nonPlayerOutcomes: localNonPlayerOutcomes,
      rulesVersion: undefined,
    });
  };

  const AddResultsButton = () => {
    return (
      <Box p={2}>
        <Button>Add Results</Button>
      </Box>
    );
  };

  const isUsed = metadata.filter((m) => {
    const used = outcomes
      .map((o) => o.scores)
      .reduce((acc, v) => [...acc, ...v], [])
      .map((s) => s.id);

    return !m.archived || (used.includes(m.id) && m.archived);
  });

  return (
    <FullScreenDialog
      id={id}
      title={"Add Play Test Results"}
      actionText="SAVE"
      onSaveHandler={onSaveHandler}
      canSave={false}
      isBusy={updatePlayTest.isPending}
      CustomControl={!editMode && <AddResultsButton />}
      Icon={Edit}
    >
      <Box>
        {minPlayers > localOutcomes.length && (
          <Alert severity="warning">
            The player count for the play test [{localOutcomes.length}] is less than the set minimum player count for
            the game [{minPlayers}]. You can still save this play test.
          </Alert>
        )}
        {maxPlayers !== undefined && localOutcomes.length > maxPlayers && (
          <Alert severity="warning">
            The player count for the play test [{localOutcomes.length}] is greater than the set maximum player count for
            the game [{minPlayers}]. You can still save this play test.
          </Alert>
        )}
        <CardHeader title="Scores" />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="center">Winner</TableCell>
              <TableCell align="left">Player Name</TableCell>
              {isUsed
                .filter((m) => m.type === "SCORE")
                .map((m) => (
                  <TableCell key={m.id} align="right">
                    {m.name}
                  </TableCell>
                ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {localOutcomes.map((player) => {
              return (
                <React.Fragment key={player.id}>
                  <TableRow>
                    <TableCell>
                      <IconButton aria-label="expand row" size="small" onClick={toggleOpen(player.id)}>
                        {open[player.id] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                      </IconButton>
                    </TableCell>
                    <TableCell align="center" style={{ width: 64 }} size="small">
                      <IconButton size="small" onClick={togglePlayerWon(player.id)}>
                        {player.won ? <Star /> : <StarOutline />}
                      </IconButton>
                    </TableCell>
                    <TableCell align="left">
                      <SmartTextField
                        size="small"
                        value={player.name}
                        initialValue={player.name}
                        onChange={updatePlayerName(player.id)}
                      />
                    </TableCell>
                    {isUsed
                      .filter((m) => m.type === "SCORE")
                      .map((m) => (
                        <TableCell key={m.id} align="right">
                          <SmartTextField
                            size="small"
                            value={getValueForMetaForPlayer(player, m.id)}
                            initialValue={getValueForMetaForPlayer(player, m.id)}
                            onChange={onPlayerMetaValueChangeHandler(player, m.id)}
                            type="number"
                            textAlign="right"
                          />
                        </TableCell>
                      ))}
                    <TableCell align="center" style={{ width: 32 }} size="small">
                      <IconButton size="small" onClick={removePlayer(player.id)}>
                        <PersonRemove />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
                      <Collapse in={!!open[player.id]} timeout="auto" unmountOnExit>
                        <CardHeader subheader={`Score breakdown for ${player.name}`} />
                        <Table size="small">
                          <TableBody>
                            {isUsed
                              .filter((m) => m.type === "PART_OF_SCORE")
                              .map((m) => (
                                <TableRow key={m.id}>
                                  <TableCell>{m.name}</TableCell>
                                  <TableCell>
                                    <Chip label={PlayTestMetadataComponentTypeLabels[m.type]} />
                                  </TableCell>
                                  <TableCell>
                                    <SmartTextField
                                      size="small"
                                      value={getValueForMetaForPlayer(player, m.id)}
                                      initialValue={getValueForMetaForPlayer(player, m.id)}
                                      onChange={onPlayerMetaValueChangeHandler(player, m.id)}
                                      type="number"
                                      textAlign="right"
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                            {isUsed
                              .filter((m) => m.type === "PER_PLAYER")
                              .map((m) => (
                                <TableRow key={m.id}>
                                  <TableCell>{m.name}</TableCell>
                                  <TableCell>
                                    <Chip label={PlayTestMetadataComponentTypeLabels[m.type]} />
                                  </TableCell>
                                  <TableCell>
                                    <SmartTextField
                                      size="small"
                                      value={getValueForMetaForPlayer(player, m.id)}
                                      initialValue={getValueForMetaForPlayer(player, m.id)}
                                      onChange={onPlayerMetaValueChangeHandler(player, m.id)}
                                      type="number"
                                      textAlign="right"
                                    />
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
          </TableBody>
        </Table>
        <Box p={2}>
          <Stack direction="row" justifyContent="space-between">
            <CardHeader title="" />
            <Box>
              <Tooltip title="Add player">
                <IconButton onClick={addPlayer}>
                  <PersonAdd />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
        </Box>
      </Box>
      {metadata.filter((m) => m.type === "PER_GAME").length > 0 && (
        <Box>
          <Divider />
          <CardHeader subheader="Game metrics" />
          <Table>
            <TableBody>
              {metadata
                .filter((m) => m.type === "PER_GAME")
                .map((m) => (
                  <TableRow key={m.id}>
                    <TableCell>{m.name}</TableCell>
                    <TableCell>
                      <Chip label={PlayTestMetadataComponentTypeLabels[m.type]} />
                    </TableCell>
                    <TableCell>
                      <SmartTextField
                        size="small"
                        value={getNonPlayerValue(nonPlayerOutcomes, m.id)}
                        initialValue={getNonPlayerValue(nonPlayerOutcomes, m.id)}
                        onChange={setNonPlayerValue(m.id)}
                        type="number"
                        textAlign="right"
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </FullScreenDialog>
  );
};
