import React from "react";
import Card from "@mui/material/Card";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { useAttachmentIdFromRoute, useGameIdFromRoute } from "../games/WithGameIdFromRoute";
import { useGame, useGameSellsheets } from "@seabrookstudios/pitch2table";
import { ImageOrPdfViewer } from "../core/ImageOrPdfViewer";
import CardHeader from "@mui/material/CardHeader";
import Download from "@mui/icons-material/Download";
import IconButton from "@mui/material/IconButton";
import { assetPath } from "../endpoints";
import Link from "@mui/material/Link";

export const SellsheetFromRoute = () => {
  const gameId = useGameIdFromRoute();
  const attachmentId = useAttachmentIdFromRoute();
  const { data: sellsheets = [] } = useGameSellsheets(gameId);
  const { data: game } = useGame(gameId);

  if (!attachmentId) {
    return <EmptyDetailCard text="Select a sellsheet from the list on the left." />;
  }

  const sellsheet = sellsheets.find((sellsheet) => sellsheet.id === attachmentId);

  return (
    <Card elevation={1} square={false}>
      {sellsheet && game && (
        <CardHeader
          action={
            <IconButton
              component={Link}
              href={assetPath(sellsheet.s3location)}
              download={`${game.name} - Sellsheet.pdf`}
            >
              <Download />
            </IconButton>
          }
        />
      )}
      <ImageOrPdfViewer document={sellsheet} />
    </Card>
  );
};
